import React, { useState, useEffect } from 'react';
import { PaginationWithEllipsis } from './PaginationWithEllipsis';

export interface Props {
  maxRecordPerPage: number;
  totalDataNum: number;
  handleClick: (e: { page: number }) => void;
}

export const PaginationWithEllipsisBilling: React.FC<Props> = ({
  maxRecordPerPage,
  totalDataNum,
  handleClick,
}: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPage = Math.ceil(totalDataNum / maxRecordPerPage);

  useEffect(() => {
    handleClick({ page: currentPage });
    const pageTop = document.getElementById('pageTop');
    if (pageTop !== null) {
      pageTop.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);

  return (
    <PaginationWithEllipsis
      currentPage={currentPage}
      totalPage={totalPage}
      handleClick={(page) => {
        if (!page || page > totalPage) return;
        setCurrentPage(page);
      }}
    />
  );
};
