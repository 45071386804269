import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Badge, Card, Col, Form, Modal, OverlayTrigger, Popover, ToggleButton } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faGripLines, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { createTestId, uuid, when } from '../../../utils/functions';
import type { NestedPartial } from '../../../interfaces/utils';
/* eslint-disable import/no-cycle */
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  IdNameOutputResponse,
  ImageUpdateFormResponse,
  IncResultOutputResponse,
  PostingRsvDetailInfoApi,
  PostingRsvDetailInfoOutputResponse,
  PostingRsvUpsertApi,
  PostingRsvUpsertFormResponse,
} from '../../../api-client';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';
import { MonitorHistoryModal } from '../../organisms/Modal/MonitorHistoryModal';
import { MonitorPreEnqueteModal } from '../../organisms/Modal/MonitorPreEnqueteModal';
import { MonitorPostEnqueteModal } from '../../organisms/Modal/MonitorPostEnqueteModal';
import { MonitorRuleSetModal } from '../../organisms/Modal/MonitorRuleSetModal';
import { MonitorHeader } from '../../organisms/MonitorHeader';
import { getImageSize, getPresignedUrl, s3Delete, s3Upload, s3UploadAlignment } from '../../../utils/s3';
import { IMAGE_TYPE_ID, OBJECT_TYPE } from '../../../Constants';
import { Url } from '../../../constants/Url';
import { MonitorListModal } from '../../organisms/Modal/MonitorListModal';
/* eslint-enable import/no-cycle */

const RATE_TYPE = ['固定', '割合'] as const;
const ENQUETE_OPTIONS = ['アンケート[社内承認]', 'アンケート[添削なし自動承認]', '提出なし'] as const;
const RECEIPT_OPTIONS = [
  'レシート[社内承認]',
  'レシート[添削なし自動承認]',
  'レシート[データ入力]',
  'その他[社内承認]',
  'その他[添削なし自動承認]',
  '納品書（画像あり）[社内承認]',
  '納品書（画像あり）[クライアント承認]',
  '納品書（画像あり）[社内＋クライアント承認]',
  '納品書（画像なし）[システム承認]',
  '納品書（画像なし）[クライアント承認]',
  '提出なし',
] as const;
const PURCHASE_BUTTON_OPTIONS = ['電話予約', 'web予約', '購入商品', 'サイトアクセス', 'デリバリー注文'] as const;
const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;
const PRODUCT_TYPE_RETURN = 2;
const CATEGORY_ID = {
  GOURMET: 1,
  SHOPPING: 3,
  MAIL_ORDER: 4,
} as const;

interface ImageProp {
  isDefault: boolean;
  id?: number;
  name?: string;
  path?: string;
  file?: File;
  contentType?: string;
  width?: number;
  height?: number;
  format?: string;
}

interface Image {
  main: ImageProp;
  sub: ImageProp[];
  free: ImageProp;
}

export interface State {
  getApi: PostingRsvDetailInfoApi;
  upsertApi: PostingRsvUpsertApi;
  commonApi: CommonMasterListApi;
  clientName?: string;
  postingRequestId: number;
  monitorBaseId: number;
  categoryId?: number;
  productType?: number;
  prefectures: string[];
  categoryOptions: CommonMasterListOutputResponse[];
  businessCategoryOptions: CommonMasterListOutputResponse[];
  genreOptions: CommonMasterListOutputResponse[];
  prefectureOptions: CommonMasterListOutputResponse[];
  apiClients: CommonMasterListOutputResponse[];
  isGenreModal: boolean;
  isHistoryModal: boolean;
  isBulkApplyModal: boolean;
  isPreEnqueteModal: boolean;
  isPostEnqueteModal: boolean;
  isRuleSetModal: boolean;
  isMonitorListModal: boolean;
  outputs: any;
  updResult: IncResultOutputResponse;
  imageData: Image;
  disableFlg: boolean;
  contractShopName?: string;
  postingRsvAlertMonitorBases: IdNameOutputResponse[];
  oldFancrewShopId?: number;
}

export const MonitorBaseModifyPage: React.VFC = () => {
  const testid = createTestId(MonitorBaseModifyPage);
  const location = useLocation<{
    clientName?: string;
    postingRequestId: number;
    monitorBaseId: number;
    disableFlg?: boolean;
    contractShopName?: string;
    postingRsvAlertMonitorBases: [];
  }>();
  const storageState = localStorage.getItem('key')
    ? JSON.parse(localStorage.getItem('key') || '')
    : { clientName: null, postingRequestId: null, monitorBaseId: null, disableFlg: false, contractShopName: null };

  const history = useHistory();
  const [wakumoniFlg, setWakumoniFlg] = useState<boolean>(false);
  const [monitorPeriod, setMonitorPeriod] = useState<number>(0);
  const [ageErrorFlg, setAgeErrorFlg] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState<string | null>(null);
  const [confirmModalResolve, setConfirmModalResolve] = useState<(value: boolean) => void>();
  const [userRewardErrorMsg, setUserRewardErrorMsg] = useState<string | null>(null);

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    getApi: new PostingRsvDetailInfoApi(),
    upsertApi: new PostingRsvUpsertApi(),
    commonApi: new CommonMasterListApi(),
    clientName: location?.state?.clientName || storageState.clientName,
    postingRequestId: location?.state?.postingRequestId || storageState.postingRequestId,
    monitorBaseId: location?.state?.monitorBaseId || storageState.monitorBaseId,
    prefectures: [],
    prefectureOptions: [],
    categoryOptions: [],
    businessCategoryOptions: [],
    genreOptions: [],
    apiClients: [],
    isGenreModal: false,
    isHistoryModal: false,
    isBulkApplyModal: false,
    isPreEnqueteModal: false,
    isPostEnqueteModal: false,
    isRuleSetModal: false,
    isMonitorListModal: false,
    outputs: {},
    updResult: { result: false },
    imageData: { main: { isDefault: false }, sub: [], free: { isDefault: false } },
    disableFlg: !!location?.state?.disableFlg || storageState.disableFlg,
    contractShopName: location?.state?.contractShopName || storageState.contractShopName,
    postingRsvAlertMonitorBases: [],
  });

  const {
    state: f,
    onChangeSet: fOnChangeSet,
    mergeState: fMergeState,
    setState: fSetState,
  } = useLargeState<NestedPartial<PostingRsvUpsertFormResponse>>({
    postingRequestId: location?.state?.postingRequestId || storageState.postingRequestId,
    monitorBaseId: location?.state?.monitorBaseId || storageState.monitorBaseId,
    borderCountAutoReleaseFlg: false,
    winningBorderCount: undefined,
    incidental: {
      budgetShowFlg: true,
      budgetSupplementShowFlg: true,
      shopBudgetShowFlg: true,
      shopBudgetSupplementShowFlg: true,
    },
    userRewardType: 1,
    winningSeparatingSetting: {
      gender: 2,
      age10sFlg: false,
      age20sFlg: false,
      age30sFlg: false,
      age40sFlg: false,
      age50sFlg: false,
      age60sFlg: false,
      age70sFlg: false,
      age80sFlg: false,
      age90sFlg: false,
      useAgeFlg: false,
      prefectureIds: [],
      ageFrom: undefined,
      ageTo: undefined,
    },
  });

  useEffect(() => {
    Promise.all(
      ['category', 'business_category', 'genre', 'prefecture'].map(
        (key): Promise<CommonMasterListOutputResponse[]> =>
          new Promise((resolve, reject) => {
            $.commonApi
              .commonMasterList(key)
              .then((res) => resolve(res.data))
              .catch((err) => reject(new Error(err)));
          })
      )
    )
      .then(([categoryOptions, businessCategoryOptions, genreOptions, prefectureOptions]) => {
        mergeState({ categoryOptions, businessCategoryOptions, genreOptions, prefectureOptions });
      })
      .catch((error) => {
        console.error(error);
      });

    $.getApi
      .postingRsvDetailInfo($.postingRequestId, $.monitorBaseId)
      .then((res: AxiosResponse<PostingRsvDetailInfoOutputResponse>) => {
        const {
          billingRewardId,
          billingRewardType,
          billingRewardValue,
          billingRewardUpperLimit,
          customerTransferFeeId,
          customerTransferFeeType,
          customerTransferFeeValue,
          customerTransferFeeUpperLimit,
          customerTransferFeeLowerLimit,
          contractBorderCount,
          preEnqueteName,
          postEnqueteName,
          ruleSetName,
          businessCategoryName,
          apiClients,
          winningSeparatingSetting,
          monitorTargetMonth,
          productType,
          mainImageId,
          mainImageUrl,
          subImages,
          freeSpaceImageId,
          freeSpaceImageUrl,
          categoryId,
          enqueteCorrectSetting,
          receiptCorrectSetting,
          userRewardValue,
          userRewardUpperLimit,
          userRewardType,
          postingRsvAlertMonitorBases,
          period,
          oldFancrewShopId,
          borderCountAutoReleaseFlg,
          winningBorderCount,
          ...rest
        } = res.data;

        setWakumoniFlg(!!res.data.wakumoniFlg);
        setMonitorPeriod(period || 0);

        mergeState({
          prefectures: winningSeparatingSetting?.prefectures?.map(({ name }: any) => name) || [],
          apiClients,
          productType,
          outputs: {
            billingRewardType,
            billingRewardValue,
            billingRewardUpperLimit,
            customerTransferFeeId,
            customerTransferFeeType,
            customerTransferFeeValue,
            customerTransferFeeUpperLimit,
            customerTransferFeeLowerLimit,
            contractBorderCount,
            preEnqueteName,
            postEnqueteName,
            ruleSetName,
            businessCategoryName,
            monitorTargetMonth,
          },
          categoryId,
          imageData: {
            main: {
              isDefault: !!mainImageId,
              id: mainImageId,
              path: mainImageUrl,
            },
            sub: subImages
              ? subImages?.map(({ id, url }) => {
                  const list: ImageProp = {
                    isDefault: !!id,
                    id,
                    path: url,
                  };
                  return list;
                })
              : [],
            free: {
              isDefault: !!freeSpaceImageId,
              id: freeSpaceImageId,
              path: freeSpaceImageUrl,
            },
          },
          postingRsvAlertMonitorBases,
          oldFancrewShopId,
        });

        let rewardType: undefined | number;
        let rewardValue: undefined | number;
        let rewardUpperLimit: undefined | number;

        if (billingRewardType === 0) {
          rewardType = 0;
          rewardValue = billingRewardValue || undefined;
        } else if (billingRewardType === 1) {
          rewardType = 1;
          rewardValue = billingRewardValue || undefined;
          rewardUpperLimit = billingRewardUpperLimit || undefined;
        } else {
          rewardType = 1;
        }

        if (winningSeparatingSetting) {
          const { prefectures, ...restWinningSetting } = winningSeparatingSetting;
          fMergeState({
            ...rest,
            userRewardType: userRewardType ?? rewardType,
            userRewardValue: userRewardValue ?? rewardValue,
            userRewardUpperLimit: userRewardUpperLimit ?? rewardUpperLimit,
            receiptCorrectSetting: receiptCorrectSetting || 0,
            enqueteCorrectSetting: enqueteCorrectSetting || 0,
            winningSeparatingSetting: { ...f.winningSeparatingSetting, ...restWinningSetting },
            borderCountAutoReleaseFlg: borderCountAutoReleaseFlg,
            winningBorderCount: winningBorderCount,
          });
        } else {
          fMergeState({
            ...rest,
            userRewardType: userRewardType ?? rewardType,
            userRewardValue: userRewardValue ?? rewardValue,
            userRewardUpperLimit: userRewardUpperLimit ?? rewardUpperLimit,
            apiClientIds: [],
            receiptCorrectSetting: receiptCorrectSetting || 0,
            enqueteCorrectSetting: enqueteCorrectSetting || 0,
            borderCountAutoReleaseFlg: borderCountAutoReleaseFlg,
            winningBorderCount: winningBorderCount,
          });
        }
      });
  }, [$.getApi, $.commonApi, $.postingRequestId, $.monitorBaseId, mergeState, fMergeState]);

  useEffect(() => {
    if (f.storeUnspecifiedFlg) {
      fMergeState({ supermarketFlg: false, convenienceStoreFlg: false, dragStoreFlg: false, otherStoreFlg: false });
    }
  }, [f.storeUnspecifiedFlg, fMergeState]);

  useEffect(() => {
    if (f.supermarketFlg || f.convenienceStoreFlg || f.dragStoreFlg || f.otherStoreFlg) {
      fMergeState({ storeUnspecifiedFlg: false });
    }
  }, [f.supermarketFlg, f.convenienceStoreFlg, f.dragStoreFlg, f.otherStoreFlg, fMergeState]);

  useEffect(() => {
    if (f.winningSeparatingSetting?.useAgeFlg) return;
    const ageFlg: Record<string, boolean> = {};
    Object.entries(f.winningSeparatingSetting || {})
      .filter(([key]) => /age[0-9]{2}sFlg/.test(key))
      .forEach(([key]) => {
        ageFlg[key] = false;
      });
    fMergeState({
      winningSeparatingSetting: { ...f.winningSeparatingSetting, ageFrom: undefined, ageTo: undefined, ...ageFlg },
    });
  }, [f.winningSeparatingSetting?.useAgeFlg, fMergeState]);

  useEffect(() => {
    if (f.userRewardType === 0) fSetState({ ...f, userRewardUpperLimit: undefined });
  }, [f.userRewardType]);

  const uploadImage = async (image: ImageProp, type: string, idx?: number) => {
    const { isDefault, path, contentType, file, width, height, format } = image;
    const imageId = image.id;
    const imageName = image.name;

    let uploadImageId: number | undefined = imageId;

    try {
      if (file && imageName && contentType && width && height && format) {
        const presignedUrl = await getPresignedUrl({
          contentType,
          fileKey: imageName,
          objectType: OBJECT_TYPE.SHOP,
        });
        const uploadTime = await s3Upload(presignedUrl, contentType, file);

        const alignmentParam: ImageUpdateFormResponse = { width, height, format };
        if (isDefault) {
          alignmentParam.imageId = imageId;
        } else {
          if (type === 'main') {
            alignmentParam.imageTypeId = IMAGE_TYPE_ID.MAIN_SHOP;
          } else if (type === 'sub') {
            alignmentParam.imageTypeId = IMAGE_TYPE_ID.SUB_SHOP;
          } else if (type === 'free') {
            alignmentParam.imageTypeId = IMAGE_TYPE_ID.FREE_SHOP;
          }
          const presignedPath = presignedUrl.replace(/\?.*$/, '');
          alignmentParam.path = presignedPath.substring(
            presignedPath.indexOf('amazonaws.com/') + 'amazonaws.com/'.length
          );
          alignmentParam.uploadTime = uploadTime;
        }

        await s3UploadAlignment(alignmentParam).then((res: IncResultOutputResponse) => {
          if (!res?.result) {
            mergeState({ updResult: res });
          }
          uploadImageId = res.id;
        });
      } else if (isDefault && !path && imageId) {
        await s3Delete(imageId).then((res) => {
          if (!res?.result) {
            mergeState({ updResult: res });
            return;
          }
          if (type === 'main') {
            mergeState({ imageData: { ...$.imageData, main: { isDefault: false } } });
          } else if (type === 'sub') {
            const newImageList = $.imageData.sub.filter((obj) => obj.id !== imageId);
            mergeState({ imageData: { ...$.imageData, sub: newImageList } });
          } else if (type === 'free') {
            mergeState({ imageData: { ...$.imageData, free: { isDefault: false } } });
          }
          uploadImageId = undefined;
        });
      }

      return uploadImageId;
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  // モーダルを表示して「はい」ならtrue、「いいえ」ならfalseを返す関数
  const showConfirm = (): Promise<boolean> => {
    setShowConfirmModal(true);
    return new Promise<boolean>((resolve) => {
      setConfirmModalResolve(() => resolve);
    });
  };

  const handleClickConfirmModalYes = () => {
    setShowConfirmModal(false);
    if (confirmModalResolve) confirmModalResolve(true); // 「はい」を押したらtrueを返す
  };

  const handleClickConfirmModalNo = () => {
    setShowConfirmModal(false);
    if (confirmModalResolve) confirmModalResolve(false); // 「いいえ」を押したらfalseを返す
  };

  /**
   * 保存
   * @param e
   */
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setAgeErrorFlg(false);
    setUserRewardErrorMsg(null);

    const ageFlgs = [
      f.winningSeparatingSetting?.age10sFlg || false,
      f.winningSeparatingSetting?.age20sFlg || false,
      f.winningSeparatingSetting?.age30sFlg || false,
      f.winningSeparatingSetting?.age40sFlg || false,
      f.winningSeparatingSetting?.age50sFlg || false,
      f.winningSeparatingSetting?.age60sFlg || false,
      f.winningSeparatingSetting?.age70sFlg || false,
      f.winningSeparatingSetting?.age80sFlg || false,
      f.winningSeparatingSetting?.age90sFlg || false,
    ];

    const ageFrom = f.winningSeparatingSetting?.ageFrom ?? undefined;
    const ageTo = f.winningSeparatingSetting?.ageTo ?? undefined;

    // 年齢条件使用フラグが true の場合
    if (f.winningSeparatingSetting?.useAgeFlg) {
      // 年代と年齢From-To どちらも指定するのは NG
      if (ageFlgs.some((ageFlg) => ageFlg) && (ageFrom || ageTo)) {
        setAgeErrorFlg(true);
        return;
      }
      // 年齢From-Toを指定する場合、両方必須。※from-toの整合性まではチェックしない。
      if (ageFlgs.every((ageFlg) => !ageFlg) && (!ageFrom || !ageTo)) {
        setAgeErrorFlg(true);
        return;
      }
    }
    // 年齢条件使用フラグが false の場合、年代、年齢from to は設定させない。
    if (!f.winningSeparatingSetting?.useAgeFlg && (ageFlgs.some((ageFlg) => ageFlg) || ageFrom || ageTo)) {
      setAgeErrorFlg(true);
      return;
    }

    // ユーザ謝礼バリデーション
    if (f.userRewardType === 1) {
      // 割合の場合
      if ($.categoryId === 6) {
        setUserRewardErrorMsg('カテゴリ「アンケート」の場合、ユーザー謝礼に「割合」は指定できません。');
        return;
      }
      if (f.receiptCorrectSetting === 10) {
        setUserRewardErrorMsg('調査証明添削有無「提出なし」の場合、ユーザー謝礼に「割合」は指定できません。');
        return;
      }
    }

    // ユーザ謝礼バリデーション2
    if (
      f.userRewardType !== $.outputs.billingRewardType ||
      f.userRewardValue !== $.outputs.billingRewardValue ||
      f.userRewardUpperLimit !== $.outputs.billingRewardUpperLimit
    ) {
      setConfirmModalTitle('謝礼が不一致ですが、このまま進行していいですか？');
      const confirmed = await showConfirm();
      if (!confirmed) {
        return; // 「いいえ」ならここで終了
      }
    }

    // 枠数バリデーション
    if (f.winningBorderCount) {
      if ($.outputs.contractBorderCount < f.winningBorderCount) {
        setUserRewardErrorMsg('当選枠数が契約枠数より多いため登録できません。');
        return;
      }

      if ($.outputs.contractBorderCount !== f.winningBorderCount) {
        setConfirmModalTitle('枠数が不一致ですが、このまま進行していいですか？');
        const confirmed = await showConfirm();
        if (!confirmed) {
          return; // 「いいえ」ならここで終了
        }
      }
    }

    try {
      const mainImageId = await uploadImage($.imageData.main, 'main');
      const freeSpaceImageId = await uploadImage($.imageData.free, 'free');

      const subImageIds = await Promise.all(
        $.imageData.sub.map(async (imageObj) => await uploadImage(imageObj, 'sub')).filter((id) => id !== undefined)
      );

      await $.upsertApi
        .postingRsvUpsert({
          ...f,
          apiClientIds: $.apiClients.map(({ id }) => id),
          winningSeparatingSetting: {
            ...f.winningSeparatingSetting,
            prefectureIds: $.prefectures.map((name) => $.prefectureOptions.find((o) => name === o.name)?.id) as any,
          },
          categoryId: $.categoryId,
          mainImageId,
          subImageIds,
          freeSpaceImageId,
        } as PostingRsvUpsertFormResponse)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          mergeState({ updResult: res.data });
          history.push({ pathname: `${Url.KEISAI.POSTING_RESERVATION}/${$.postingRequestId}` });
        })
        .catch((err: IncResultOutputResponse) => {
          mergeState({ updResult: err });
        });
    } catch {
      history.push(Url.COMMON_ERROR);
    }

    mergeState({ disableFlg: false });
  };

  const onDrop = async (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const { name, type: contentType } = file;
    const path = window.URL.createObjectURL(file);
    const format = name.split('.').pop();
    const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

    mergeState({
      imageData: {
        ...$.imageData,
        main: { ...$.imageData.main, ...size, file, name, contentType, format, path, isDefault: false },
      },
    });
  };

  // ドラッグアンドドロップイベント
  const handleOnDragEndItem = (result: any) => {
    if (result.destination === null) return;

    const items = Object.assign([], $.imageData?.sub);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination!.index, 0, reorderedItem);

    mergeState({ imageData: { ...$.imageData, sub: items } });
  };

  // サブ画像イベント
  const onSubDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles === null || acceptedFiles.length === 0) return;

    const subImages = await Promise.all(
      acceptedFiles.map(async (file) => {
        const { name, type: contentType } = file;
        const path = window.URL.createObjectURL(file);
        const format = name.split('.').pop();
        const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));
        return { ...size, file, name, contentType, format, path, isDefault: false };
      })
    );

    mergeState({ imageData: { ...$.imageData, sub: $.imageData.sub.concat(subImages) } });
  };

  // 店舗画像イベント
  const onShopDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles === null || acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];

    const { name, type: contentType } = file;
    const path = window.URL.createObjectURL(file);
    const format = name.split('.').pop();
    const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

    mergeState({
      imageData: { ...$.imageData, free: { ...$.imageData.free, ...size, file, name, contentType, format, path } },
    });
  };

  const unseatedSettingOptions: [string, number][] = Object.entries({
    落選しない: 0,
    '落選する（無期限）': 999,
    '落選する（1ヶ月間）': 1,
    '落選する（2ヶ月間）': 2,
    '落選する（3ヶ月間）': 3,
    '落選する（4ヶ月間）': 4,
    '落選する（5ヶ月間）': 5,
    '落選する（6ヶ月間）': 6,
    '落選する（7ヶ月間）': 7,
    '落選する（8ヶ月間）': 8,
    '落選する（9ヶ月間）': 9,
    '落選する（10ヶ月間）': 10,
    '落選する（11ヶ月間）': 11,
    '落選する（12ヶ月間）': 12,
  });
  const productTypeOptions: [string, number][] = Object.entries({
    通常: 0,
    スマート: 1,
    リターン: 2,
  });

  return (
    <>
      <Modal show={showConfirmModal} onHide={handleClickConfirmModalNo}>
        <Modal.Header style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{confirmModalTitle}</Modal.Header>
        <Modal.Footer>
          <Button className="me-2" variant="secondary" onClick={handleClickConfirmModalNo}>
            いいえ
          </Button>
          <Button variant="primary" onClick={handleClickConfirmModalYes}>
            はい
          </Button>
        </Modal.Footer>
      </Modal>
      <MonitorRuleSetModal
        isModal={$.isRuleSetModal}
        onSave={(id, name) => {
          fMergeState({ ruleSetId: id });
          mergeState({ isRuleSetModal: false, outputs: { ...$.outputs, ruleSetName: name } });
        }}
        onHide={() => mergeState({ isRuleSetModal: false })}
      />
      <MonitorPostEnqueteModal
        isModal={$.isPostEnqueteModal}
        onSave={(id, name) => {
          fMergeState({ postEnqueteId: id });
          mergeState({ isPostEnqueteModal: false, outputs: { ...$.outputs, postEnqueteName: name } });
        }}
        onHide={() => mergeState({ isPostEnqueteModal: false })}
        client={$.clientName}
      />
      <MonitorPreEnqueteModal
        isModal={$.isPreEnqueteModal}
        onSave={(id, name) => {
          fMergeState({ preEnqueteId: id });
          mergeState({ isPreEnqueteModal: false, outputs: { ...$.outputs, preEnqueteName: name } });
        }}
        onHide={() => mergeState({ isPreEnqueteModal: false })}
      />
      {/* <MonitorBulkApplyModal isModal={$.isBulkApplyModal} setIsModal={useBindSet('isBulkApplyModal')} /> */}
      <MonitorHistoryModal
        isModal={$.isHistoryModal}
        setIsModal={useBindSet('isHistoryModal')}
        monitorId={$.monitorBaseId}
        monitorName={f.monitorName || ''}
      />

      <MonitorListModal
        isModal={$.isMonitorListModal}
        setIsModal={useBindSet('isMonitorListModal')}
        state={$}
        mergeState={mergeState}
        fState={f}
        fMergeState={fMergeState}
      />

      <Form onSubmit={onSubmit}>
        <MonitorHeader disabled={$.disableFlg} setIsModal={useBindSet('isMonitorListModal')} />
        <Title className="mb-4" data-testid={testid('title')}>
          {TITLE.KEISAI.MONITOR_BASE_MODIFY}
        </Title>

        {$.updResult?.result && (
          <Alert variant="success" data-testid={testid('success-alert')}>
            確定しました。
          </Alert>
        )}
        {$.updResult?.errorCode && $.updResult?.errorMessage && (
          <Alert
            variant="danger"
            data-testid={testid('failure-alert')}
          >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
        )}

        {$.postingRsvAlertMonitorBases && $.postingRsvAlertMonitorBases.length > 0 && (
          <Alert testId={testid('posting-rsv-alert')} variant="danger">
            別の掲載依頼で掲載予約が作成されています。
          </Alert>
        )}

        {ageErrorFlg && <Alert variant="danger">年齢条件を正しく選択してください。</Alert>}
        {userRewardErrorMsg && <Alert variant="danger">{userRewardErrorMsg}</Alert>}

        <Form.Group className="mb-4" data-testid={testid('contractShopName')}>
          <Form.Label className="fw-bold">契約店舗名</Form.Label>
          <Form.Control type="text" value={$.contractShopName} readOnly disabled />
        </Form.Group>
        <Form.Group className="mb-4" data-testid={testid('monitorName')}>
          <Form.Label className="fw-bold">
            モニター名
            <span className="text-danger">*</span>
            &emsp;枠モニ種別：
            <Badge pill bg={wakumoniFlg ? 'success' : 'secondary'}>
              {wakumoniFlg ? '枠モ二' : '通常'}
            </Badge>
            &emsp;商品タイプ：
            {productTypeOptions.map(([label, value]) => (
              <React.Fragment key={uuid()}>
                {when(
                  $.productType === value,
                  <Badge pill bg="secondary">
                    {label}
                  </Badge>
                )}
              </React.Fragment>
            ))}
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={f.monitorName || ''}
            onChange={fOnChangeSet('monitorName')}
            disabled={$.disableFlg}
          />
        </Form.Group>

        <div className="d-flex justify-content-between gap-4 w-100">
          <div className="w-50">
            <Card className="mb-4">
              <Card.Header className="fw-bold">謝礼</Card.Header>
              <Card.Body>
                <Form.Group className="mb-4" data-testid={testid('customerTransferFee')}>
                  <Form.Label>送客手数料</Form.Label>
                  <div className="d-flex bg-light p-4 gap-2">
                    <div className="col-4 text-center">
                      <div className="border-bottom  border-dark fw-bold pb-2">謝礼計算</div>
                      <div className="pt-2">
                        {Number.isInteger($.outputs.customerTransferFeeType)
                          ? RATE_TYPE[$.outputs.customerTransferFeeType]
                          : '-'}
                      </div>
                    </div>
                    {$.outputs.customerTransferFeeType !== 0 && (
                      <div className="d-flex text-center flex-grow-1">
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">割合率</div>
                          <div className="pt-2">{$.outputs.customerTransferFeeValue}%</div>
                        </div>
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">下限金額</div>
                          <div className="pt-2">{$.outputs.customerTransferFeeLowerLimit}円</div>
                        </div>
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">上限金額</div>
                          <div className="pt-2">{$.outputs.customerTransferFeeUpperLimit}円</div>
                        </div>
                      </div>
                    )}
                    {$.outputs.customerTransferFeeType === 0 && (
                      <div className="d-flex text-center flex-grow-1">
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">金額</div>
                          <div className="pt-2">{$.outputs.customerTransferFeeValue}円</div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('billingReward')}>
                  <Form.Label>請求謝礼</Form.Label>
                  <div className="d-flex bg-light p-4 gap-2">
                    <div className="col-4 text-center">
                      <div className="border-bottom  border-dark fw-bold pb-2">謝礼計算</div>
                      <div className="pt-2">
                        {Number.isInteger($.outputs.billingRewardType) ? RATE_TYPE[$.outputs.billingRewardType] : '-'}
                      </div>
                    </div>
                    {$.outputs.billingRewardType !== 0 && (
                      <div className="d-flex text-center flex-grow-1">
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">割合率</div>
                          <div className="pt-2">{$.outputs.billingRewardValue}%</div>
                        </div>
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">上限金額</div>
                          <div className="pt-2">{$.outputs.billingRewardUpperLimit}円</div>
                        </div>
                      </div>
                    )}
                    {$.outputs.billingRewardType === 0 && (
                      <div className="d-flex text-center flex-grow-1">
                        <div className="flex-grow-1">
                          <div className="border-bottom  border-dark fw-bold pb-2">金額</div>
                          <div className="pt-2">{$.outputs.billingRewardValue}円</div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('userReward')}>
                  <Form.Label>ユーザー謝礼設定</Form.Label>
                  <div className="d-flex bg-light p-4 gap-2">
                    <div className="col-4 text-center">
                      <div className="border-bottom  border-dark fw-bold pb-2">
                        謝礼計算{$.productType !== PRODUCT_TYPE_RETURN && <span className="text-danger">*</span>}
                      </div>
                      <Form.Select
                        required={$.productType !== PRODUCT_TYPE_RETURN}
                        disabled={$.productType === PRODUCT_TYPE_RETURN || $.disableFlg}
                        className="mx-auto mt-2 w-50"
                        value={f.userRewardType ?? '1'}
                        onChange={(e) =>
                          fMergeState({
                            userRewardType: e.target.value !== '' ? Number(e.target.value) : undefined,
                          })
                        }
                      >
                        {RATE_TYPE.map((label, val) => (
                          <option key={label} value={val}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="d-flex text-center flex-grow-1">
                      {f.userRewardType === 0 && (
                        <>
                          <div className="flex-grow-1">
                            <div className="border-bottom  border-dark fw-bold pb-2">
                              固定料金{$.productType !== PRODUCT_TYPE_RETURN && <span className="text-danger">*</span>}
                            </div>
                            <div className="d-flex justify-content-center align-items-end mt-2">
                              <Form.Control
                                required={$.productType !== PRODUCT_TYPE_RETURN}
                                disabled={$.productType === PRODUCT_TYPE_RETURN || $.disableFlg}
                                type="number"
                                min={0}
                                className="w-50"
                                value={f.userRewardValue ?? ''}
                                onChange={fOnChangeSet('userRewardValue', Number)}
                              />
                              <span>円</span>
                            </div>
                          </div>
                        </>
                      )}
                      {f.userRewardType === 1 && (
                        <>
                          <div className="flex-grow-1">
                            <div className="border-bottom  border-dark fw-bold pb-2">
                              割合率{$.productType !== PRODUCT_TYPE_RETURN && <span className="text-danger">*</span>}
                            </div>
                            <div className="d-flex justify-content-center align-items-end mt-2">
                              <Form.Control
                                required={$.productType !== PRODUCT_TYPE_RETURN}
                                disabled={$.productType === PRODUCT_TYPE_RETURN || $.disableFlg}
                                type="number"
                                min={0}
                                className="w-50"
                                value={f.userRewardValue ?? ''}
                                onChange={fOnChangeSet('userRewardValue', Number)}
                              />
                              <span>%</span>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div className="border-bottom  border-dark fw-bold pb-2">
                              上限金額{$.productType !== PRODUCT_TYPE_RETURN && <span className="text-danger">*</span>}
                            </div>
                            <div className="d-flex justify-content-center align-items-end mt-2">
                              <Form.Control
                                required={$.productType !== PRODUCT_TYPE_RETURN}
                                disabled={$.productType === PRODUCT_TYPE_RETURN || $.disableFlg}
                                type="number"
                                min={0}
                                className="w-50"
                                value={f.userRewardUpperLimit ?? ''}
                                onChange={fOnChangeSet('userRewardUpperLimit', Number)}
                              />
                              <span>円</span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('rewardMessageChange')}>
                  <Form.Label>謝礼の文言変更</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={2}
                    value={f.rewardMessageChange || ''}
                    onChange={fOnChangeSet('rewardMessageChange')}
                    disabled={$.disableFlg}
                  />
                </Form.Group>

                <Form.Group data-testid={testid('rewardGrantTimeMessageChange')}>
                  <Form.Label>謝礼付与時期の文言変更</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={2}
                    value={f.rewardGrantTimeMessageChange || ''}
                    onChange={fOnChangeSet('rewardGrantTimeMessageChange')}
                    disabled={$.disableFlg}
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">アンケート・モニタールール</Card.Header>
              <Card.Body>
                <Form.Group className="mb-4" data-testid={testid('preEnquete')}>
                  <Form.Label>事前アンケート</Form.Label>

                  <div className="position-relative">
                    <Form.Control
                      value={$.outputs.preEnqueteName || ''}
                      className="bg-white py-3"
                      onChange={() => {}}
                      disabled={$.disableFlg}
                    />
                    <div className="position-absolute top-50 translate-middle-y" style={{ right: '1rem' }}>
                      {f.preEnqueteId && (
                        <Button
                          variant="link"
                          className="text-secondary"
                          onClick={() => {
                            const { preEnqueteName, ...outputs } = $.outputs;
                            mergeState({ outputs });
                            const { preEnqueteId, ...forms } = f;
                            fSetState(forms);
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </Button>
                      )}

                      <Button
                        className="text-nowrap"
                        onClick={() => mergeState({ isPreEnqueteModal: true })}
                        disabled={$.disableFlg}
                      >
                        選択
                      </Button>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('postEnquete')}>
                  <Form.Label>
                    事後アンケート<span className="text-danger">*</span>
                  </Form.Label>

                  <div className="position-relative">
                    <Form.Control
                      required
                      value={$.outputs.postEnqueteName || ''}
                      className="bg-white py-3"
                      onChange={() => {}}
                      disabled={$.disableFlg}
                    />
                    <div className="position-absolute top-50 translate-middle-y" style={{ right: '1rem' }}>
                      {f.postEnqueteId && (
                        <Button
                          variant="link"
                          className="text-secondary"
                          onClick={() => {
                            const { postEnqueteName, ...outputs } = $.outputs;
                            mergeState({ outputs });
                            const { postEnqueteId, ...forms } = f;
                            fSetState(forms);
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </Button>
                      )}

                      <Button
                        className="text-nowrap"
                        onClick={() => mergeState({ isPostEnqueteModal: true })}
                        disabled={$.disableFlg}
                      >
                        選択
                      </Button>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('ruleSet')}>
                  <Form.Label>
                    モニタールールセット<span className="text-danger">*</span>
                  </Form.Label>

                  <div className="position-relative">
                    <Form.Control
                      required
                      value={$.outputs.ruleSetName || ''}
                      className="bg-white py-3"
                      onChange={() => {}}
                      disabled={$.disableFlg}
                    />
                    <div className="position-absolute top-50 translate-middle-y" style={{ right: '1rem' }}>
                      {f.ruleSetId && (
                        <Button
                          variant="link"
                          className="text-secondary"
                          onClick={() => {
                            const { ruleSetName, ...outputs } = $.outputs;
                            mergeState({ outputs });
                            const { ruleSetId, ...forms } = f;
                            fSetState(forms);
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </Button>
                      )}

                      <Button
                        className="text-nowrap"
                        onClick={() => mergeState({ isRuleSetModal: true })}
                        disabled={$.disableFlg}
                      >
                        選択
                      </Button>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('enqueteCorrectSetting')}>
                  <Form.Label>
                    アンケート添削有無<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={f.enqueteCorrectSetting ?? '0'}
                    required
                    onChange={(e) =>
                      fMergeState({
                        enqueteCorrectSetting: e.target.value !== '' ? Number(e.target.value) : undefined,
                      })
                    }
                    disabled={$.disableFlg}
                  >
                    {ENQUETE_OPTIONS.map((v, i) => (
                      <option key={v} value={i}>
                        {v}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group data-testid={testid('receiptCorrectSetting')}>
                  <Form.Label>
                    調査証明添削有無<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={f.receiptCorrectSetting || '0'}
                    required
                    onChange={(e) =>
                      fMergeState({
                        receiptCorrectSetting: e.target.value !== '' ? Number(e.target.value) : undefined,
                      })
                    }
                    disabled={$.disableFlg}
                  >
                    {RECEIPT_OPTIONS.map((v, i) => (
                      <option key={v} value={i}>
                        {v}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-4" data-testid={testid('businessCategory')}>
              <Card.Header className="fw-bold">カテゴリ</Card.Header>
              <Card.Body>
                <Form.Group className="mb-4">
                  <Form.Label className="fw-bold">
                    カテゴリ<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    required
                    value={$.categoryId || ''}
                    onChange={(e) => {
                      mergeState({
                        categoryId: Number(e.target.value),
                        outputs: { ...$.outputs, businessCategoryName: undefined },
                      });
                      fMergeState({ categoryId: Number(e.target.value), businessCategoryId: undefined });
                      if ($.categoryId !== CATEGORY_ID.MAIL_ORDER) fSetState({ ...f, urlForPurchase: undefined });
                      if (Number(e.target.value) === CATEGORY_ID.GOURMET) {
                        fSetState({ ...f, deadlineExtensionFlg: true });
                      } else {
                        fSetState({ ...f, deadlineExtensionFlg: false });
                      }
                    }}
                    disabled={$.disableFlg}
                  >
                    <option hidden value="">
                      選択してください
                    </option>
                    {$.categoryOptions?.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* categoryがグルメだったら */}
                {when(
                  $.categoryId === 1,
                  <RecommendFloatingLabel
                    required
                    value={$.outputs.businessCategoryName || ''}
                    label="提供業態"
                    disable={$.categoryId !== 1 || $.disableFlg}
                    options={$.businessCategoryOptions.map(({ name }) => name)}
                    onChange={(e) => {
                      const current = $.businessCategoryOptions.find(({ name }) => name === e.target.value);
                      if (current) {
                        fMergeState({ businessCategoryId: current.id });
                      } else {
                        const { businessCategoryId, ...forms } = f;
                        fSetState(forms);
                      }
                      mergeState({ outputs: { ...$.outputs, businessCategoryName: e.target.value } });
                    }}
                    onDelete={() => {
                      const { businessCategoryName, ...outputs } = $.outputs;
                      mergeState({ outputs });
                      const { businessCategoryId, ...forms } = f;
                      fSetState(forms);
                    }}
                    onClickItem={(e) => {
                      const current = $.businessCategoryOptions.find(({ name }) => name === e.currentTarget.innerHTML);
                      if (current) {
                        mergeState({ outputs: { ...$.outputs, businessCategoryName: e.currentTarget.innerHTML } });
                        fMergeState({ businessCategoryId: current.id });
                      }
                    }}
                  />
                )}
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">自由記入欄</Card.Header>
              <Card.Body className="d-flex align-items-end" data-testid={testid('freeEntryField')}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={f.freeEntryField || ''}
                  onChange={fOnChangeSet('freeEntryField')}
                  disabled={$.disableFlg}
                />
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Header className="fw-bold">予約説明</Card.Header>
              <Card.Body className="d-flex align-items-end" data-testid={testid('reserveDescription')}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={f.reserveDescription || ''}
                  onChange={fOnChangeSet('reserveDescription')}
                  disabled={$.disableFlg}
                />
              </Card.Body>
            </Card>

            {$.categoryId === CATEGORY_ID.MAIL_ORDER && (
              <Card className="mb-4" data-testid={testid('purchaseButtonSettingGroup')}>
                <Card.Header className="fw-bold">
                  <div className="d-flex align-items-center">
                    <div>購入ボタン設定</div>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover id="popover-basic" className="mw-100">
                          <Popover.Body className="p-1">
                            通販モニターの
                            <br />
                            購入ボタン設定項目
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                      </span>
                    </OverlayTrigger>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form.Select
                    className="mb-4"
                    data-testid={testid('purchaseButtonSetting')}
                    value={f.purchaseButtonSetting || ''}
                    onChange={(e) =>
                      fMergeState({
                        purchaseButtonSetting: e.target.value !== '' ? Number(e.target.value) : undefined,
                      })
                    }
                    disabled={$.disableFlg}
                  >
                    <option value="">&nbsp;</option>
                    {PURCHASE_BUTTON_OPTIONS.map((v, i) => (
                      <option key={v} value={i + 1}>
                        {v}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Group className="mb-4" data-testid={testid('callReceptiontimeForPurchase')}>
                    <Form.Label>電話受付時間</Form.Label>
                    <Form.Control
                      type="text"
                      value={f.callReceptiontimeForPurchase || ''}
                      onChange={fOnChangeSet('callReceptiontimeForPurchase')}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>

                  <Form.Group data-testid={testid('phoneNumberForPurchase')}>
                    <Form.Label>電話番号</Form.Label>
                    <Form.Control
                      type="text"
                      value={f.phoneNumberForPurchase || ''}
                      onChange={fOnChangeSet('phoneNumberForPurchase')}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            )}

            <Card className="mb-4">
              <Card.Header className="fw-bold">有料サービス使用可否</Card.Header>
              <Card.Body>
                <Form.Group className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div>モニター期限延長</div>
                    <OverlayTrigger
                      placement="bottom-start"
                      overlay={
                        <Popover id="popover-basic" className="mw-100">
                          <Popover.Body className="p-1">
                            ユーザーのモニター提出期限を延長する有料サービス使用を許可する
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <Form.Check
                    type="switch"
                    data-testid={testid('deadlineExtensionFlg')}
                    checked={f.deadlineExtensionFlg || true}
                    onChange={() => fMergeState({ deadlineExtensionFlg: !f.deadlineExtensionFlg })}
                    disabled={$.disableFlg}
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">予算情報</Card.Header>
              <Card.Body>
                <div className="bg-light p-4">
                  <Form.Group className="mb-4">
                    <Form.Label>店舗の平均予算（一人当たり）</Form.Label>
                    <div className="d-flex align-items-end">
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Popover id="incidental" className="p-1">
                            ファンくるでの
                            <br />
                            表示/非表示を切り替え
                          </Popover>
                        }
                      >
                        <button
                          type="button"
                          className="btn text-secondary me-1"
                          onClick={() => {
                            fMergeState({
                              incidental: { ...f.incidental, shopBudgetShowFlg: !f.incidental?.shopBudgetShowFlg },
                            });
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={f.incidental?.shopBudgetShowFlg ? faEye : faEyeSlash} />
                        </button>
                      </OverlayTrigger>
                      <Form.Group className="flex-grow-1" data-testid={testid('shopBudgetMinValue')}>
                        <Form.Control
                          type="number"
                          min={0}
                          value={f.incidental?.shopBudgetMinValue || ''}
                          onChange={(e) =>
                            fMergeState({
                              incidental: {
                                ...f.incidental,
                                shopBudgetMinValue: e.target.value !== '' ? Number(e.target.value) : undefined,
                              },
                            })
                          }
                          disabled={$.disableFlg}
                        />
                      </Form.Group>
                      <span className="mx-1">円&nbsp;～</span>
                      <Form.Group className="flex-grow-1" data-testid={testid('shopBudgetMaxValue')}>
                        <Form.Control
                          type="number"
                          min={0}
                          value={f.incidental?.shopBudgetMaxValue || ''}
                          onChange={(e) =>
                            fMergeState({
                              incidental: {
                                ...f.incidental,
                                shopBudgetMaxValue: e.target.value !== '' ? Number(e.target.value) : undefined,
                              },
                            })
                          }
                          disabled={$.disableFlg}
                        />
                      </Form.Group>
                      <span className="ms-1">円</span>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>店舗の平均予算 補足文</Form.Label>
                    <div className="d-flex align-items-end">
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Popover id="incidental" className="p-1">
                            ファンくるでの
                            <br />
                            表示/非表示を切り替え
                          </Popover>
                        }
                      >
                        <button
                          type="button"
                          className="btn text-secondary me-1"
                          onClick={() => {
                            fMergeState({
                              incidental: {
                                ...f.incidental,
                                shopBudgetSupplementShowFlg: !f.incidental?.shopBudgetSupplementShowFlg,
                              },
                            });
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={f.incidental?.shopBudgetSupplementShowFlg ? faEye : faEyeSlash} />
                        </button>
                      </OverlayTrigger>
                      <Form.Group className="flex-grow-1" data-testid={testid('shopBudgetSupplement')}>
                        <Form.Control
                          type="text"
                          value={f.incidental?.shopBudgetSupplement || ''}
                          onChange={(e) =>
                            fMergeState({
                              incidental: { ...f.incidental, shopBudgetSupplement: e.target.value },
                            })
                          }
                          disabled={$.disableFlg}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>モニター予算</Form.Label>
                    <div className="d-flex align-items-end">
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Popover id="incidental" className="p-1">
                            ファンくるでの
                            <br />
                            表示/非表示を切り替え
                          </Popover>
                        }
                      >
                        <button
                          type="button"
                          className="btn text-secondary me-1"
                          onClick={() => {
                            fMergeState({
                              incidental: { ...f.incidental, budgetShowFlg: !f.incidental?.budgetShowFlg },
                            });
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={f.incidental?.budgetShowFlg ? faEye : faEyeSlash} />
                        </button>
                      </OverlayTrigger>
                      <Form.Group className="flex-grow-1" data-testid={testid('budgetMinValue')}>
                        <Form.Control
                          type="number"
                          min={0}
                          value={f.incidental?.budgetMinValue || ''}
                          onChange={(e) =>
                            fMergeState({
                              incidental: {
                                ...f.incidental,
                                budgetMinValue: e.target.value !== '' ? Number(e.target.value) : undefined,
                              },
                            })
                          }
                          disabled={$.disableFlg}
                        />
                      </Form.Group>
                      <span className="mx-1">円&nbsp;～</span>
                      <Form.Group className="flex-grow-1" data-testid={testid('budgetMaxValue')}>
                        <Form.Control
                          type="number"
                          min={0}
                          value={f.incidental?.budgetMaxValue || ''}
                          onChange={(e) =>
                            fMergeState({
                              incidental: {
                                ...f.incidental,
                                budgetMaxValue: e.target.value !== '' ? Number(e.target.value) : undefined,
                              },
                            })
                          }
                          disabled={$.disableFlg}
                        />
                      </Form.Group>
                      <span className="ms-1">円</span>
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>モニター予算 補足文</Form.Label>
                    <div className="d-flex align-items-end">
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Popover id="incidental" className="p-1">
                            ファンくるでの
                            <br />
                            表示/非表示を切り替え
                          </Popover>
                        }
                      >
                        <button
                          type="button"
                          className="btn text-secondary me-1"
                          onClick={() => {
                            fMergeState({
                              incidental: {
                                ...f.incidental,
                                budgetSupplementShowFlg: !f.incidental?.budgetSupplementShowFlg,
                              },
                            });
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={f.incidental?.budgetSupplementShowFlg ? faEye : faEyeSlash} />
                        </button>
                      </OverlayTrigger>
                      <Form.Group className="flex-grow-1" data-testid={testid('budgetSupplement')}>
                        <Form.Control
                          type="text"
                          value={f.incidental?.budgetSupplement || ''}
                          onChange={(e) =>
                            fMergeState({
                              incidental: { ...f.incidental, budgetSupplement: e.target.value },
                            })
                          }
                          disabled={$.disableFlg}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </div>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header className="fw-bold card-header">旧基盤店舗ID</Card.Header>
              <Card.Body className="d-flex align-items-end" data-testid={testid('oldFancrewShopId')}>
                <Form.Control type="text" value={$.oldFancrewShopId || ''} readOnly disabled />
              </Card.Body>
            </Card>
          </div>

          <div className="w-50">
            <Card className="mb-4">
              <Card.Header className="fw-bold">モニター実施タイミング情報</Card.Header>
              <Card.Body>
                <Form.Group className="mb-4" data-testid={testid('startDate')}>
                  <Form.Label>
                    開始日時<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="d-flex gap-2">
                    <Form.Control
                      required
                      type="date"
                      className="w-auto"
                      value={f.applicationStartDate || ''}
                      onChange={fOnChangeSet('applicationStartDate')}
                      disabled={$.disableFlg}
                    />
                  </div>
                </Form.Group>

                <Form.Group data-testid={testid('months')}>
                  <Form.Label>対象月</Form.Label>
                  <div className="d-flex flex-wrap gap-2">
                    {MONTHS.map((m, i) => (
                      <ToggleButton
                        id={m}
                        key={m}
                        value={m}
                        type="checkbox"
                        variant="outline-primary"
                        style={{ width: 'calc(16.7% - 0.5rem)' }}
                        checked={
                          $.outputs?.monitorTargetMonth ? $.outputs?.monitorTargetMonth[`${m}ImplementationFlg`] : false
                        }
                        disabled
                      >
                        {i + 1}
                      </ToggleButton>
                    ))}
                  </div>
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">枠数</Card.Header>
              <Card.Body>
                <div className="bg-light p-4">
                  <div className="d-flex text-center flex-grow-1">
                    <div className="flex-grow-1">
                      <div className="border-bottom  border-dark fw-bold pb-2">契約枠数</div>
                      <div className="pt-3">{$.outputs.contractBorderCount}</div>
                    </div>
                    <div className="flex-grow-1">
                      <div className="border-bottom  border-dark fw-bold pb-2">
                        当選枠数<span className="text-danger">*</span>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-end mt-2"
                        data-testid={testid('winningBorderCount')}
                      >
                        <Form.Control
                          required
                          type="number"
                          min={0}
                          className="w-50"
                          value={f.winningBorderCount || ''}
                          onChange={fOnChangeSet('winningBorderCount', Number)}
                          disabled={$.disableFlg}
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div className="border-bottom  border-dark fw-bold pb-2">
                        <div className="d-flex justify-content-center align-items-center">
                          <div>枠数自動解放</div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Popover id="popover-basic" className="mw-100">
                                <Popover.Body className="p-1">
                                  1日、5日、10日、15日の
                                  <br />
                                  4分割で枠を開放する
                                  <br />
                                  枠モニは枠数自動開放の設定不可
                                  <br />
                                  モニター期間が2か月以上は
                                  <br />
                                  枠数自動開放の設定不可
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-end mt-3"
                        data-testid={testid('borderCountAutoReleaseFlg')}
                      >
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={f.borderCountAutoReleaseFlg || false}
                          onChange={() => fMergeState({ borderCountAutoReleaseFlg: !f.borderCountAutoReleaseFlg })}
                          disabled={$.disableFlg || wakumoniFlg || monitorPeriod > 1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">当落設定</Card.Header>
              <Card.Body>
                <Form.Group className="mb-4" data-testid={testid('gender')}>
                  <Form.Label>
                    性別<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={f.winningSeparatingSetting?.gender === undefined ? 2 : f.winningSeparatingSetting?.gender}
                    onChange={(e) =>
                      fMergeState({
                        winningSeparatingSetting: { ...f.winningSeparatingSetting, gender: Number(e.target.value) },
                      })
                    }
                    disabled={$.disableFlg}
                  >
                    <option value="2">全て</option>
                    <option value="0">男性</option>
                    <option value="1">女性</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex align-items-center">
                    <div>年齢条件使用</div>
                    <OverlayTrigger
                      placement="bottom-start"
                      overlay={
                        <Popover id="popover-basic" className="mw-100">
                          <Popover.Body className="p-1">
                            年齢条件使用ONの場合、ONにされた年代、または入力された年齢の範囲が当選対象になります
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <Form.Check
                    type="switch"
                    id="useAgeFlg"
                    data-testid={testid('useAgeFlg')}
                    checked={f.winningSeparatingSetting?.useAgeFlg || false}
                    onChange={() =>
                      fMergeState({
                        winningSeparatingSetting: {
                          ...f.winningSeparatingSetting,
                          useAgeFlg: !f.winningSeparatingSetting?.useAgeFlg,
                        },
                      })
                    }
                    disabled={$.disableFlg}
                  />
                </Form.Group>

                <Form.Group className="mb-4" data-testid={testid('age')}>
                  <Form.Label>年齢</Form.Label>
                  <div className="d-flex flex-wrap gap-2">
                    {Object.entries(f.winningSeparatingSetting || {})
                      .filter(([key]) => /age[0-9]{2}sFlg/.test(key))
                      .map(([key, val]) => (
                        <ToggleButton
                          id={key}
                          key={key}
                          value={key || ''}
                          type="checkbox"
                          variant="outline-primary"
                          className="w-auto"
                          checked={val as boolean}
                          onChange={() =>
                            fMergeState({
                              winningSeparatingSetting: { ...f.winningSeparatingSetting, [key]: !val },
                            })
                          }
                          disabled={$.disableFlg}
                        >
                          {key.replace(/[^0-9]/g, '')}代
                        </ToggleButton>
                      ))}
                  </div>
                  <div className="mt-2">または</div>
                  <Form.Group className="d-flex align-items-center mb-4" data-testid={testid('ageFromTo')}>
                    <Form.Label className="mb-0">年齢：</Form.Label>
                    <Form.Select
                      id="ageFrom"
                      style={{ width: '80px' }}
                      value={f.winningSeparatingSetting?.ageFrom ?? ''}
                      onChange={(e) =>
                        fMergeState({
                          winningSeparatingSetting: {
                            ...f.winningSeparatingSetting,
                            ageFrom: e.target.value !== '' ? Number(e.target.value) : undefined,
                          },
                        })
                      }
                      disabled={$.disableFlg}
                    >
                      <option value="">&nbsp;</option>
                      {[...Array(85)].map((_, i) => (
                        <option key={i + 16} value={i + 16}>
                          {i + 16}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Label className="mb-0">歳～</Form.Label>
                    <Form.Select
                      id="ageTo"
                      style={{ width: '80px' }}
                      value={f.winningSeparatingSetting?.ageTo ?? ''}
                      onChange={(e) =>
                        fMergeState({
                          winningSeparatingSetting: {
                            ...f.winningSeparatingSetting,
                            ageTo: e.target.value !== '' ? Number(e.target.value) : undefined,
                          },
                        })
                      }
                      disabled={$.disableFlg}
                    >
                      <option value="">&nbsp;</option>
                      {[...Array(85)].map((_, i) => (
                        <option key={i + 16} value={i + 16}>
                          {i + 16}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Label className="mb-0">歳</Form.Label>
                  </Form.Group>
                </Form.Group>

                <div className="bg-light p-4 mb-4">
                  <div className="fw-bold border-bottom border-white pb-4">同一クライアント当落設定</div>

                  <Form.Group className="mb-4 mt-2" data-testid={testid('sameClientPastMonitorSeparateSetting')}>
                    <div className="d-flex align-items-center mb-2">
                      <div>過去モニター実施済ユーザーを除外</div>
                      <OverlayTrigger
                        placement="top-start"
                        overlay={
                          <Popover id="popover-basic" className="mw-100">
                            <Popover.Body className="p-1">
                              同一クライアントの過去モニターを
                              <br />
                              実施したユーザーをこのモニターの
                              <br />
                              表示対象から除外する
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <span>
                          <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                        </span>
                      </OverlayTrigger>
                    </div>
                    <Form.Select
                      value={f.sameClientPastMonitorSeparateSetting ?? ''}
                      onChange={(e) =>
                        fMergeState({
                          sameClientPastMonitorSeparateSetting:
                            e.target.value !== '' ? Number(e.target.value) : undefined,
                        })
                      }
                      disabled={$.disableFlg}
                    >
                      <option value="">&nbsp;</option>
                      {unseatedSettingOptions.map(([label, value]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group
                    className="d-flex justify-content-between align-items-center"
                    data-testid={testid('sameClientWinningWithinUnseatedFlg')}
                  >
                    <div className="d-flex align-items-center">
                      <div>当選中ユーザーを除外</div>
                      <OverlayTrigger
                        placement="bottom-start"
                        overlay={
                          <Popover id="popover-basic" className="mw-100">
                            <Popover.Body className="p-1">
                              同一クライアントのモニターに当選中のユーザーをこのモニターの表示対象から除外する
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <span>
                          <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                        </span>
                      </OverlayTrigger>
                    </div>
                    <Form.Check
                      type="switch"
                      id="sameClientWinningWithinUnseatedFlg"
                      checked={f.sameClientWinningWithinUnseatedFlg || false}
                      onChange={() =>
                        fMergeState({ sameClientWinningWithinUnseatedFlg: !f.sameClientWinningWithinUnseatedFlg })
                      }
                      disabled={$.disableFlg}
                    />
                  </Form.Group>
                </div>

                <Form.Group className="mb-4 mt-2" data-testid={testid('sameClientPastMonitorUnseatedSetting')}>
                  <Form.Label>モニター リピーター当選設定</Form.Label>
                  <Form.Select
                    value={f.sameClientPastMonitorUnseatedSetting ?? ''}
                    onChange={(e) =>
                      fMergeState({
                        sameClientPastMonitorUnseatedSetting:
                          e.target.value !== '' ? Number(e.target.value) : undefined,
                      })
                    }
                    disabled={$.disableFlg}
                  >
                    <option value="">&nbsp;</option>
                    {unseatedSettingOptions.map(([label, value]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="d-flex justify-content-between align-items-center mb-4"
                  data-testid={testid('billingReward')}
                >
                  <div className="d-flex align-items-center">
                    <div>抽選待ち</div>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover id="popover-basic" className="mw-100">
                          <Popover.Body className="p-1">????要テキスト</Popover.Body>
                        </Popover>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <Form.Check type="switch" id="custom-switch" disabled={$.disableFlg} />
                </Form.Group>

                <Form.Group
                  className="d-flex justify-content-between align-items-center"
                  data-testid={testid('canApplyMultipleTimesFlg')}
                >
                  <div className="d-flex align-items-center">
                    <div>複数回応募可能</div>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover id="popover-basic" className="mw-100">
                          <Popover.Body className="p-1">????要テキスト</Popover.Body>
                        </Popover>
                      }
                    >
                      <span>
                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <Form.Check
                    type="switch"
                    id="canApplyMultipleTimesFlg"
                    checked={f.canApplyMultipleTimesFlg || false}
                    onChange={() => fMergeState({ canApplyMultipleTimesFlg: !f.canApplyMultipleTimesFlg })}
                    disabled={$.disableFlg}
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            {$.categoryId === CATEGORY_ID.MAIL_ORDER && (
              <Card>
                <Card.Header className="fw-bold">購入URL</Card.Header>
                <Card.Body>
                  <Form.Control
                    type="text"
                    value={f.urlForPurchase || ''}
                    onChange={fOnChangeSet('urlForPurchase')}
                    disabled={$.disableFlg}
                  />
                </Card.Body>
              </Card>
            )}

            {$.categoryId === CATEGORY_ID.SHOPPING && (
              <Card className="mb-4" data-testid={testid('shoppingGroup')}>
                <Card.Header className="fw-bold">ショッピング（購入場所）</Card.Header>
                <Card.Body>
                  <Form.Group
                    className="d-flex justify-content-between align-items-center mb-4"
                    data-testid={testid('storeUnspecifiedFlg')}
                  >
                    <Form.Label className="mb-0">指定なし</Form.Label>
                    <Form.Check
                      type="switch"
                      id="storeUnspecifiedFlg"
                      checked={f.storeUnspecifiedFlg || false}
                      onChange={() => fMergeState({ storeUnspecifiedFlg: !f.storeUnspecifiedFlg })}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>

                  <Form.Group
                    className="d-flex justify-content-between align-items-center mb-4"
                    data-testid={testid('supermarketFlg')}
                  >
                    <Form.Label className="mb-0">スーパー</Form.Label>
                    <Form.Check
                      type="switch"
                      id="supermarketFlg"
                      checked={f.supermarketFlg || false}
                      onChange={() => fMergeState({ supermarketFlg: !f.supermarketFlg })}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>

                  <Form.Group
                    className="d-flex justify-content-between align-items-center mb-4"
                    data-testid={testid('convenienceStoreFlg')}
                  >
                    <Form.Label className="mb-0">コンビニ</Form.Label>
                    <Form.Check
                      type="switch"
                      id="convenienceStoreFlg"
                      checked={f.convenienceStoreFlg || false}
                      onChange={() => fMergeState({ convenienceStoreFlg: !f.convenienceStoreFlg })}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>

                  <Form.Group
                    className="d-flex justify-content-between align-items-center mb-4"
                    data-testid={testid('dragStoreFlg')}
                  >
                    <Form.Label className="mb-0">ドラッグストア</Form.Label>
                    <Form.Check
                      type="switch"
                      id="dragStoreFlg"
                      checked={f.dragStoreFlg || false}
                      onChange={() => fMergeState({ dragStoreFlg: !f.dragStoreFlg })}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>

                  <Form.Group
                    className="d-flex justify-content-between align-items-center"
                    data-testid={testid('otherStoreFlg')}
                  >
                    <Form.Label className="mb-0">その他</Form.Label>
                    <Form.Check
                      type="switch"
                      id="otherStoreFlg"
                      checked={f.otherStoreFlg || false}
                      onChange={() => fMergeState({ otherStoreFlg: !f.otherStoreFlg })}
                      disabled={$.disableFlg}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            )}

            <Card className="mb-4">
              <Card.Header className="fw-bold">
                <div className="d-flex align-items-center">
                  <div>カウントダウン設定</div>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Popover id="popover-basic" className="mw-100">
                        <Popover.Body className="p-1">?????????????????????????????要テキスト</Popover.Body>
                      </Popover>
                    }
                  >
                    <span>
                      <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                    </span>
                  </OverlayTrigger>
                </div>
              </Card.Header>
              <Card.Body>
                <Form.Group
                  className="d-flex justify-content-between align-items-center mb-4"
                  data-testid={testid('countdownCurrent')}
                >
                  <Form.Label className="mb-0 col-5">今月</Form.Label>
                  <Form.Select
                    className="flex-grow-1"
                    value={f.countdownCurrent || ''}
                    onChange={(e) =>
                      fMergeState({
                        countdownCurrent: e.target.value !== '' ? Number(e.target.value) : undefined,
                      })
                    }
                    disabled={$.disableFlg}
                  >
                    <option value="">&nbsp;</option>
                    {[...Array(18)].map((_, i) => (
                      <option key={i + 11} value={i + 11}>
                        {i + 11}日
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="d-flex justify-content-between align-items-center"
                  data-testid={testid('countdownSpot')}
                >
                  <Form.Label className="mb-0 col-5">スポット・閉店</Form.Label>
                  <Form.Control
                    type="date"
                    className="flex-grow-1"
                    value={f.countdownSpot || ''}
                    onChange={fOnChangeSet('countdownSpot')}
                    disabled={$.disableFlg}
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">営業時間</Card.Header>
              <Card.Body className="d-flex align-items-end" data-testid={testid('businessHours')}>
                <Form.Control
                  type="text"
                  value={f.businessHours || ''}
                  onChange={fOnChangeSet('businessHours')}
                  disabled={$.disableFlg}
                />
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">休日</Card.Header>
              <Card.Body className="d-flex align-items-end" data-testid={testid('fixedHoliday')}>
                <Form.Control
                  type="text"
                  value={f.fixedHoliday || ''}
                  onChange={fOnChangeSet('fixedHoliday')}
                  disabled={$.disableFlg}
                />
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">送料</Card.Header>
              <Card.Body className="d-flex align-items-end" data-testid={testid('shippingFee')}>
                <Form.Control
                  type="number"
                  value={f.shippingFee || ''}
                  onChange={fOnChangeSet('shippingFee')}
                  disabled={$.disableFlg}
                />
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header className="fw-bold">実店舗画像</Card.Header>
              <Card.Body>
                <Form.Group>
                  <Form.Label className="fw-bold">店舗画像(メイン)※1つのみアップ可能</Form.Label>
                  {$.imageData?.main.path && (
                    <Card className="p-2" style={{ marginBottom: '0.5rem', width: 'calc(100% - 50%)' }}>
                      <div className="d-flex p-0">
                        <img className="m-2" src={$.imageData?.main.path} alt="sample" width="83%" />
                        <Button
                          variant="link"
                          className=" text-secondary"
                          data-html
                          onClick={() => {
                            mergeState({
                              imageData: {
                                ...$.imageData,
                                main: { isDefault: $.imageData.main.isDefault, id: undefined },
                              },
                            });
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon className="text-secondary" icon={faTimesCircle} />
                        </Button>
                      </div>
                    </Card>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  {when(
                    !$.imageData.main.path,
                    <Dropzone onDrop={onDrop} multiple={false} disabled={$.disableFlg}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          style={{
                            border: '3px dotted #c0c0c0',
                            color: '#a0a0a0',
                            background: '#FFF',
                            textAlign: 'center',
                            height: '200px',
                            width: '50%',
                          }}
                        >
                          <input {...getInputProps()} />
                          <div style={{ marginTop: 65 }}>
                            ここにファイルをドラッグ
                            <br />
                            <Button variant="link" disabled={$.disableFlg}>
                              <p style={{ borderBottom: 'solid' }}>ファイルを添付</p>
                            </Button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-bold">店舗画像(サブ)※最大11枚アップ可能</Form.Label>
                  <DragDropContext onDragEnd={handleOnDragEndItem}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <Col
                          className="p-2"
                          style={{ marginBottom: '0.5rem', width: 'calc(100% - 50%)' }}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {$.imageData.sub?.map((file, index: number) => (
                            <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                              {(provided2) => (
                                <Card
                                  className={index + 1 === $.imageData.sub?.length ? 'p-2' : 'p-2 mb-3'}
                                  style={{ marginBottom: '0.5rem', width: 'calc(100% - 50%)' }}
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                >
                                  <div className="d-flex p-0">
                                    <Button
                                      variant="link"
                                      className="text-secondary"
                                      {...provided2.dragHandleProps}
                                      disabled={$.disableFlg}
                                    >
                                      <FontAwesomeIcon className="text-secondary" icon={faGripLines} />
                                    </Button>
                                    <img className="m-2" src={file.path} alt="sample" width="70%" />
                                    <Button
                                      variant="link"
                                      className="text-secondary"
                                      data-html
                                      onClick={() => {
                                        const copySubImgList = [...$.imageData.sub];
                                        mergeState({
                                          imageData: {
                                            ...$.imageData,
                                            sub: copySubImgList.filter((d, i) => i !== index),
                                          },
                                        });
                                      }}
                                      disabled={$.disableFlg}
                                    >
                                      <FontAwesomeIcon icon={faTimesCircle} className="text-secondary" />
                                    </Button>
                                  </div>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Col>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Form.Group>
                <Form.Group className="mb-4">
                  {$.imageData?.sub.length < 11 && (
                    <Dropzone onDrop={onSubDrop} multiple disabled={$.disableFlg}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          style={{
                            border: '3px dotted #c0c0c0',
                            color: '#a0a0a0',
                            background: '#FFF',
                            textAlign: 'center',
                            height: '200px',
                            width: '50%',
                          }}
                        >
                          <input {...getInputProps()} />
                          <div style={{ marginTop: 65 }}>
                            ここにファイルをドラッグ
                            <br />
                            <Button variant="link" disabled={$.disableFlg}>
                              <p style={{ borderBottom: 'solid' }}>ファイルを添付</p>
                            </Button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-bold">店舗画像(フリースペース)※1つのみアップ可能</Form.Label>
                  {$.imageData?.free.path && (
                    <Card className="p-2" style={{ marginBottom: '0.5rem', width: 'calc(100% - 50%)' }}>
                      <div className="d-flex p-0">
                        <img
                          className="m-2"
                          src={$.imageData?.free.path ? $.imageData?.free.path : undefined}
                          alt="sample"
                          width="83%"
                        />
                        <Button
                          variant="link"
                          className="text-secondary"
                          data-html
                          onClick={() => {
                            mergeState({
                              imageData: {
                                ...$.imageData,
                                free: { isDefault: $.imageData.free.isDefault, id: undefined },
                              },
                            });
                          }}
                          disabled={$.disableFlg}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} className="text-secondary" />
                        </Button>
                      </div>
                    </Card>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  {when(
                    !$.imageData.free.path,
                    <Dropzone onDrop={onShopDrop} multiple disabled={$.disableFlg}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          style={{
                            border: '3px dotted #c0c0c0',
                            color: '#a0a0a0',
                            background: '#FFF',
                            textAlign: 'center',
                            height: '200px',
                            width: '50%',
                          }}
                        >
                          <input {...getInputProps()} />
                          <div style={{ marginTop: 65 }}>
                            ここにファイルをドラッグ
                            <br />
                            <Button variant="link" disabled={$.disableFlg}>
                              <p style={{ borderBottom: 'solid' }}>ファイルを添付</p>
                            </Button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Form>
    </>
  );
};
