import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingSpinner.css';

/**
 * 読み込み中はスピナーを表示、そうでない時は子要素を表示
 */
export const BillingLoading = () => {
  return (
    <div className="loading-spinner">
      <div className="loading-spinner mask">
        <div className="loading-spinner spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    </div>
  );
};
