import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import Select from 'react-select';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BillingListTable } from '../../organisms/Table/BillingListTable';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { BillingApi, BillingInvoiceListOutputResponse } from '../../../api-client';
import { convertNumberArray, getTargetMonth } from '../../../utils/functionsForBilling';
import {
  BILLING_APPROVER_ROLE_NAME_ARRAY,
  BILLING_FLOW_STATUS,
  BILLING_FLOW_STATUS_FOR_SELECTION,
} from '../../../BillingConstants';
import useBillingSuggestClient from '../../../hooks/useBillingSuggestClient';
import useBillingSuggestIncAccount from '../../../hooks/useBillingSuggestIncAccount';
import useBillingSuggestContractShop from '../../../hooks/useBillingSuggestContractShop';
import useBillingSuggestAgent from '../../../hooks/useBillingSuggestAgent';
import useBillingSuggestBillingApproveIncAccount from '../../../hooks/useBillingSuggestBillingApproveIncAccount';
import { Url } from '../../../constants/Url';
import { getHistoryState } from '../../../utils/functions';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';
import { BillingLoading } from '../../molecules/Loading/BillingLoading';

// TODO 元に戻す
const MAX_RECORD_PER_PAGE = 20;

interface ROLE {
  isSales: number;
  isSmanager: number;
  isAccounting: number;
}

const DEFAULT_STATUS_VALUE: ROLE = {
  isSales: 0,
  isSmanager: 1,
  isAccounting: 2,
};

type SearchParams = {
  approverIncAccountId?: number;
  billingAgentId?: number;
  billingFlowStatuses?: Array<number>;
  clientId?: number;
  contractShopId?: number;
  hasBilling: boolean;
  publishBillingFlg: boolean;
  salesIncAccountId?: number;
  targetMonth: string;
  deliveryNote?: string;
  billingHeaderId?: number;
  pageNum?: number;
  pagePerItems: number;
};

export const BillingListPage: React.VFC = () => {
  const { role } = useParams<{ role: keyof ROLE | undefined }>();
  const [data, setData] = useState<BillingInvoiceListOutputResponse>({
    billingInvoiceListDetailOutput: [],
    totalCount: 0,
  });
  const PAGE_PER_ITEMS = 20; // 1ページに表示する明細数
  const { currentUser } = useCurrentUser();
  const { addQueryStringToUrl, setSearchQuery, getSearchParams, removeSearchQuery, getQueryString } = useSearchQuery();
  const billingSuggestClientResult = useBillingSuggestClient();
  const billingSuggestIncAccountResult = useBillingSuggestIncAccount();
  const billingSuggestContractShopResult = useBillingSuggestContractShop();
  const billingSuggestAgentResult = useBillingSuggestAgent();
  const billingSuggestBillingApproveIncAccount = useBillingSuggestBillingApproveIncAccount();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const initSearchCondition: () => SearchParams = () => {
    let searchConditions = {
      clientId: getSearchParams('clientId') !== null ? Number(getSearchParams('clientId')) : undefined, // クライアント
      salesIncAccountId: getSearchParams('salesIncAccountId')
        ? Number(getSearchParams('salesIncAccountId'))
        : undefined, // 担当者
      contractShopId:
        getSearchParams('contractShopId') !== null ? Number(getSearchParams('contractShopId')) : undefined, // 店舗
      targetMonth: getSearchParams('targetMonth') !== null ? getSearchParams('targetMonth')! : getTargetMonth(), // 請求書発行日
      billingFlowStatuses:
        getSearchParams('billingFlowStatus') !== null
          ? convertNumberArray(getSearchParams('billingFlowStatus'))
          : undefined, // 請求フローステータス
      hasBilling: getSearchParams('hasBilling') !== null ? Boolean(Number(getSearchParams('hasBilling'))) : true, // 請求書有無
      publishBillingFlg:
        getSearchParams('publishBillingFlg') !== null ? Boolean(Number(getSearchParams('publishBillingFlg'))) : true, // 流し込み請求書作成
      billingAgentId:
        getSearchParams('billingAgentId') !== null ? Number(getSearchParams('billingAgentId')) : undefined, // 代理店
      approverIncAccountId:
        getSearchParams('approverIncAccountId') !== null ? Number(getSearchParams('approverIncAccountId')) : undefined, // 承認担当者
      deliveryNote: getSearchParams('deliveryNote') !== null ? getSearchParams('deliveryNote')! : undefined, // 配送メモ
      billingHeaderId:
        getSearchParams('billingHeaderId') !== null ? Number(getSearchParams('billingHeaderId')) : undefined, // 請求書ID
      pageNum: getSearchParams('pageNum') !== null ? Number(getSearchParams('pageNum')) : 1,
      pagePerItems: getSearchParams('pagePerItems') !== null ? Number(getSearchParams('pagePerItems')) : 20,
    } as SearchParams;

    if (!getSearchParams('targetMonth')) {
      // 検索で値が指定されていない場合はデフォルト値と返す
      if (!role) return searchConditions;
      if (DEFAULT_STATUS_VALUE[role] === DEFAULT_STATUS_VALUE.isSales) {
        // 営業確認をする場合
        searchConditions = {
          ...searchConditions,
          ...{
            billingFlowStatuses: [
              BILLING_FLOW_STATUS.WAITING_COMPLETE_APPLY,
              BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED,
            ],
            salesIncAccountId: currentUser!.id,
          },
        };
      }
      if (DEFAULT_STATUS_VALUE[role] === DEFAULT_STATUS_VALUE.isSmanager) {
        // 上長承認をする場合
        searchConditions = {
          ...searchConditions,
          ...{
            billingFlowStatuses: [BILLING_FLOW_STATUS.SALES_DPT_MANAGER_UNCOMMITTED],
          },
        };
        if (typeof currentUser?.role === 'string' && BILLING_APPROVER_ROLE_NAME_ARRAY.includes(currentUser.role)) {
          // 上長承認をする場合
          searchConditions = {
            ...searchConditions,
            ...{
              approverIncAccountId: currentUser!.id,
            },
          };
        }
      }
      if (DEFAULT_STATUS_VALUE[role] === DEFAULT_STATUS_VALUE.isAccounting) {
        // 管理部確認をする場合
        searchConditions = {
          ...searchConditions,
          ...{
            billingFlowStatuses: [BILLING_FLOW_STATUS.MANAGE_DPT_UNCOMMITTED],
          },
        };
        if (typeof currentUser?.role === 'string' && BILLING_APPROVER_ROLE_NAME_ARRAY.includes(currentUser?.role)) {
          // 管理部確認をする場合
          searchConditions = {
            ...searchConditions,
            ...{
              approverIncAccountId: currentUser!.id,
            },
          };
        }
      }
    }
    return searchConditions;
  };

  const billingApi = new BillingApi();

  async function fetchData(pageNum = 1) {
    const formData = getValues();
    // const convert = convertNanToUndefined(searchParam) as SearchParams;
    const res = await billingApi.billingInvoiceList(
      formData.clientId, // クライアント
      formData.salesIncAccountId, // 担当者
      formData.contractShopId, // 店舗
      formData.targetMonth, // 請求書発行日
      formData.billingFlowStatuses, // 請求フローステータス
      formData.hasBilling, // 請求書有無
      formData.billingAgentId, // 代理店
      formData.approverIncAccountId, // 承認担当者
      formData.publishBillingFlg, // 請求書発行フラグ
      formData.deliveryNote === '' || false ? undefined : formData.deliveryNote, // 配送メモ
      Number.isNaN(formData.billingHeaderId) ? undefined : formData.billingHeaderId, // 請求書ID
      pageNum,
      PAGE_PER_ITEMS
    );
    setData(res.data);
    return true;
  }

  const onClickPageNation = (clickPage: number) => {
    (async () => {
      setIsLoading((prev) => !prev);
      const result = await fetchData(clickPage);
      setIsLoading((prev) => !prev);
    })();
  };

  const setSearchQueryValue = () => {
    const clientId = getValues('clientId');
    if (clientId !== undefined) {
      setSearchQuery('clientId', clientId.toString());
    } else {
      removeSearchQuery('clientId');
    }

    const salesIncAccountId = getValues('salesIncAccountId');
    if (salesIncAccountId !== undefined) {
      setSearchQuery('salesIncAccountId', salesIncAccountId.toString());
    } else {
      removeSearchQuery('salesIncAccountId');
    }

    const contractShopId = getValues('contractShopId');
    if (contractShopId !== undefined) {
      setSearchQuery('contractShopId', contractShopId.toString());
    } else {
      removeSearchQuery('contractShopId');
    }

    const targetMonth = getValues('targetMonth');
    if (targetMonth !== undefined) {
      setSearchQuery('targetMonth', targetMonth);
    } else {
      removeSearchQuery('targetMonth');
    }

    const billingFlowStatuses = getValues('billingFlowStatuses');
    if (billingFlowStatuses !== undefined && billingFlowStatuses.length > 0) {
      setSearchQuery('billingFlowStatus', billingFlowStatuses.toString());
    } else {
      // 検索パラメータがundefinedの場合は「全て」扱いなのでパラメータから取り除く
      removeSearchQuery('billingFlowStatus');
    }

    const hasBilling = getValues('hasBilling');
    if (hasBilling !== undefined) {
      setSearchQuery('hasBilling', hasBilling ? '1' : '0');
    } else {
      removeSearchQuery('hasBilling');
    }

    const billingAgentId = getValues('billingAgentId');
    if (billingAgentId !== undefined) {
      setSearchQuery('billingAgentId', billingAgentId.toString());
    } else {
      removeSearchQuery('billingAgentId');
    }

    const approverIncAccountId = getValues('approverIncAccountId');
    if (approverIncAccountId !== undefined) {
      setSearchQuery('approverIncAccountId', approverIncAccountId.toString());
    } else {
      removeSearchQuery('approverIncAccountId');
    }

    const publishBillingFlg = getValues('publishBillingFlg');
    if (publishBillingFlg !== undefined) {
      setSearchQuery('publishBillingFlg', publishBillingFlg ? '1' : '0');
    } else {
      removeSearchQuery('publishBillingFlg');
    }

    const deliveryNote = getValues('deliveryNote');
    if (deliveryNote !== undefined) {
      setSearchQuery('deliveryNote', deliveryNote);
    } else {
      removeSearchQuery('deliveryNote');
    }

    const billingHeaderId = getValues('billingHeaderId');
    if (billingHeaderId !== undefined && !Number.isNaN(billingHeaderId)) {
      setSearchQuery('billingHeaderId', billingHeaderId.toString());
    } else {
      removeSearchQuery('billingHeaderId');
    }
    const pageNum = getValues('pageNum');
    if (pageNum !== undefined && !Number.isNaN(pageNum)) {
      setSearchQuery('pageNum', pageNum.toString());
    } else {
      removeSearchQuery('pageNum');
    }
    const pagePerItems = getValues('pagePerItems');
    if (pagePerItems !== undefined && !Number.isNaN(pagePerItems)) {
      setSearchQuery('pagePerItems', pagePerItems.toString());
    } else {
      removeSearchQuery('pagePerItems');
    }
  };

  // 初回
  useEffect(() => {
    setSearchQueryValue();
    (async () => {
      await fetchData();
    })();
  }, []);

  const handleSearch = () => {
    setSearchQueryValue();
    addQueryStringToUrl();
    // eslint-disable-next-line func-names
    (async () => {
      await fetchData();
    })();
  };

  useEffect(() => {
    const noReload = getHistoryState(history.location.state, 'noReload');
    if (role && !noReload && getQueryString() === '') {
      history.push(Url.BILLING.BLANK, { nextPath: `${Url.BILLING.BILLING_LIST}/${role}` });
    }
  }, [role]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
  } = useForm<SearchParams>({
    defaultValues: initSearchCondition(),
  });
  const onSubmit: SubmitHandler<SearchParams> = () => {
    handleSearch();
  };

  useEffect(() => {
    console.log('isLoading');
    console.log(isLoading);
  }, [isLoading]);
  return (
    <>
      {isLoading && <BillingLoading />}
      <Form onSubmit={handleSubmit(onSubmit)} id="pageTop">
        <Container>
          <Row className="g-2 mb-2">
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="clientId">
                  クライアント
                </Form.Label>
                <Col sm={8}>
                  {billingSuggestClientResult.isSuccess && (
                    <Select
                      id="clientId"
                      isSearchable
                      isClearable
                      options={billingSuggestClientResult.data}
                      defaultValue={billingSuggestClientResult.data?.find((v) => v.value === getValues('clientId'))}
                      onChange={(e) => setValue('clientId', e?.value)}
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="salesIncAccountId">
                  担当営業
                </Form.Label>
                <Col sm={8}>
                  {billingSuggestIncAccountResult.isSuccess && (
                    <Select
                      id="salesIncAccountId"
                      isSearchable
                      isClearable
                      options={billingSuggestIncAccountResult.data}
                      defaultValue={billingSuggestIncAccountResult.data?.find(
                        (v) => v.value === getValues('salesIncAccountId')
                      )}
                      onChange={(e) => setValue('salesIncAccountId', e?.value)}
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-2">
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="contractShopId">
                  契約店舗
                </Form.Label>
                <Col sm={8}>
                  {billingSuggestContractShopResult.isSuccess && (
                    <Select
                      id="contractShopId"
                      isSearchable
                      isClearable
                      options={billingSuggestContractShopResult.data}
                      defaultValue={billingSuggestContractShopResult.data?.find(
                        (v) => v.value === getValues('contractShopId')
                      )}
                      onChange={(e) => setValue('contractShopId', e?.value)}
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="targetMonth">
                  請求書発行月
                </Form.Label>
                <Col sm={8}>
                  <Form.Control id="targetMonth" type="month" {...register('targetMonth')} />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-2">
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="billingFlowStatus">
                  請求フローステータス
                </Form.Label>
                <Col sm={8}>
                  <Select
                    id="billingFlowStatus"
                    isSearchable
                    isClearable
                    options={BILLING_FLOW_STATUS_FOR_SELECTION}
                    defaultValue={BILLING_FLOW_STATUS_FOR_SELECTION.filter((v) =>
                      getValues('billingFlowStatuses')?.includes(v.value)
                    )}
                    isMulti
                    onChange={(e) => {
                      let selectedFlowList: Array<number> = [];
                      e.map((v) => {
                        selectedFlowList = [...selectedFlowList, v.value];
                      });
                      setValue('billingFlowStatuses', selectedFlowList);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="hasBilling">
                  請求書有無
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    id="hasBilling"
                    onChange={(e) => setValue('hasBilling', e.target.value === '1')}
                    defaultValue={getValues('hasBilling') ? 1 : 0}
                  >
                    <option value={1}>有</option>
                    <option value={0}>無</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-2">
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="billingAgentId">
                  代理店
                </Form.Label>
                <Col sm={8}>
                  {billingSuggestAgentResult.isSuccess && (
                    <Select
                      id="billingAgentId"
                      isSearchable
                      isClearable
                      options={billingSuggestAgentResult.data}
                      defaultValue={billingSuggestAgentResult.data?.find(
                        (v) => v.value === getValues('billingAgentId')
                      )}
                      onChange={(e) => setValue('billingAgentId', e?.value)}
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="approverIncAccountId">
                  承認担当者
                </Form.Label>
                <Col sm={8}>
                  {billingSuggestBillingApproveIncAccount.isSuccess && (
                    <Select
                      id="approverIncAccountId"
                      isSearchable
                      isClearable
                      options={billingSuggestBillingApproveIncAccount.data}
                      defaultValue={billingSuggestBillingApproveIncAccount.data?.find(
                        (v) => v.value === getValues('approverIncAccountId')
                      )}
                      onChange={(e) => setValue('approverIncAccountId', e?.value)}
                    />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-2">
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="publishBillingFlg">
                  流し込み請求書作成
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    id="publishBillingFlg"
                    defaultValue={getValues('publishBillingFlg') ? 1 : 0}
                    onChange={(e) => setValue('publishBillingFlg', e.target.value === '1')}
                  >
                    <option value={0}>する</option>
                    <option value={1}>しない</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="deliveryNote">
                  配送メモ
                </Form.Label>
                <Col sm={8}>
                  <Form.Control id="deliveryNote" type="text" {...register('deliveryNote')} />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-2">
            <Col sm={6}>
              <Form.Group className="d-flex">
                <Form.Label column sm={4} className="ml-2" htmlFor="billingHeaderId">
                  請求書ID
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    id="billingHeaderId"
                    type="number"
                    {...register('billingHeaderId', { valueAsNumber: true })}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button variant="secondary" className="m-2" type="submit">
              検索
            </Button>
          </div>
        </Container>
      </Form>
      <BillingListTable billingMonth={getValues('targetMonth')} data={data} setData={setData} />
      {data.totalCount > 0 && (
        <div className="mt-4 d-flex justify-content-center">
          <PaginationWithEllipsisBilling
            maxRecordPerPage={MAX_RECORD_PER_PAGE}
            totalDataNum={data.totalCount}
            handleClick={(e) => onClickPageNation(e.page)}
          />
        </div>
      )}
    </>
  );
};
