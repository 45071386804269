import React, { useMemo } from 'react';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { when } from '../../../../../utils/functions';
import { Button } from '../../../../atoms/Button';
import {
  CanCorrectType,
  DisplayControlEscalate,
  DisplayControlReject,
  ResultModalMode,
} from '../../../../pages/Correction/constants';
import { VISIT_AT_CHECK_RESULT_TYPE } from '../../../../../Constants';

export const ResultFormSection: React.FC = () => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);
  const [isExec, isReject, isQuestion, isOK] = useMemo(() => {
    const { canForceCorrect, correctionExec, escalation, reject } =
      $.res_correctReceiptDisplayControl ?? $.res_correctEnqueteDisplayControl ?? {};
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const isExec = correctionExec === CanCorrectType.CORRECTING;
    return [
      isExec || canForceCorrect,
      (isExec && reject !== DisplayControlReject.DISABLE) || canForceCorrect,
      isExec && escalation === DisplayControlEscalate.DISPLAY,
      $.isEnquete
        ? true
        : $.receiptForm_tellCheck?.result &&
          $.receiptForm_receiptCheck?.result &&
          // 警告の場合は承認できるようにする
          $.receiptForm_dateCheck?.result !== VISIT_AT_CHECK_RESULT_TYPE.NG,
    ];
  }, [
    $.res_correctReceiptDisplayControl,
    $.res_correctEnqueteDisplayControl,
    $.isEnquete,
    $.receiptForm_tellCheck?.result,
    $.receiptForm_receiptCheck?.result,
    $.receiptForm_dateCheck?.result,
  ]);

  const hasEscalationComment = useMemo(() => !!($.commentForms_escale?.length !== 0), [$.commentForms_escale]);
  const hasNgComment = useMemo(() => !!($.commentForms_ng?.length !== 0), [$.commentForms_ng]);

  return (
    <>
      <Button
        variant="success"
        disabled={!isExec || !isOK || hasEscalationComment || hasNgComment}
        onClick={() => isExec && isOK && mergeState({ isResultModal: true, resultFormMode: ResultModalMode.OK })}
      >
        添削OKへ進む
      </Button>
      {when(!isOK, <ResultFormError message="電話番号/来店日時/レシートNoのチェック項目を埋めてください" />)}
      {when(hasEscalationComment, <ResultFormError message="エスカレ項目が存在しているため、OKできません。" />)}
      {when(hasNgComment, <ResultFormError message="NG項目が存在しているため、OKできません。" />)}
      <hr />
      <Button
        variant="warning"
        disabled={!isExec || !hasNgComment || hasEscalationComment}
        onClick={() => isExec && mergeState({ isResultModal: true, resultFormMode: ResultModalMode.RESEND })}
      >
        添削NG - 再提出へ進む
      </Button>
      {when(
        hasEscalationComment,
        <ResultFormError message="エスカレ項目が存在しているため、再提出依頼ができません。" />
      )}
      <hr />
      <Button
        variant="danger"
        disabled={!isReject || hasEscalationComment || hasNgComment}
        onClick={() => isReject && mergeState({ isResultModal: true, resultFormMode: ResultModalMode.REJECT })}
      >
        添削NG - 却下へ進む
      </Button>
      {when(
        hasEscalationComment,
        <ResultFormError message="エスカレ項目が存在しているため、却下承認依頼ができません。" />
      )}
      {when(hasNgComment, <ResultFormError message="NG項目が存在しているため、却下承認依頼ができません。" />)}
      <hr />
      <Button
        variant="info"
        disabled={!isQuestion || !hasEscalationComment}
        onClick={() => isQuestion && mergeState({ isResultModal: true, resultFormMode: ResultModalMode.ESCALE })}
      >
        事務所へ質問へ進む
      </Button>
      {when(
        !hasEscalationComment,
        <ResultFormError message="エスカレ項目が存在しないため、事務局への質問ができません。" />
      )}
    </>
  );
};

export const ResultFormError: React.FC<{ message: string }> = ({ message }) => {
  return <p style={{ color: 'red' }}>{message}</p>;
};
