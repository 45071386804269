/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BillingUseItemOutputResponse } from './billing-use-item-output-response';
import { ClientSelectionOutputResponse } from './client-selection-output-response';

/**
 * 申請コメント
 * @export
 * @interface BillingAgentFeeDetailForEditModalOutputResponse
 */
export interface BillingAgentFeeDetailForEditModalOutputResponse {
    /**
     * 請求利用商品IDのリスト
     * @type {Array<BillingUseItemOutputResponse>}
     * @memberof BillingAgentFeeDetailForEditModalOutputResponse
     */
    'billingUseItemIds': Array<BillingUseItemOutputResponse>;
    /**
     * クライアントIDのリスト
     * @type {Array<ClientSelectionOutputResponse>}
     * @memberof BillingAgentFeeDetailForEditModalOutputResponse
     */
    'clientSelectionIds': Array<ClientSelectionOutputResponse>;
    /**
     * フィータイプのリスト(固定/割合)
     * @type {Array<string>}
     * @memberof BillingAgentFeeDetailForEditModalOutputResponse
     */
    'rateTypes': Array<BillingAgentFeeDetailForEditModalOutputResponseRateTypesEnum>;
}

export const BillingAgentFeeDetailForEditModalOutputResponseRateTypesEnum = {
    Fixed: 'FIXED',
    Ratio: 'RATIO'
} as const;

export type BillingAgentFeeDetailForEditModalOutputResponseRateTypesEnum = typeof BillingAgentFeeDetailForEditModalOutputResponseRateTypesEnum[keyof typeof BillingAgentFeeDetailForEditModalOutputResponseRateTypesEnum];


