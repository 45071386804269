import React, { useState, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Button, Table } from 'react-bootstrap';
import { BillingListMemoModal, DeliveryNoteUpdate } from '../Modal/BillingListMemoModal';
// eslint-disable-next-line import/no-cycle
import {
  BillingApi,
  BillingInvoiceListOutputResponse,
  BillingInvoiceDestinationListOutputResponse,
  BillingInvoiceDestinationSalesInfoOutputResponse,
  BillingInvoiceListDetailOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
// eslint-disable-next-line import/no-cycle
import { BILLING_FLOW_STATUS, FLOW_STATUS_STRING } from '../../../BillingConstants';
// eslint-disable-next-line import/no-cycle
import { convertMoneyText } from '../../../utils/functionsForBilling';
// eslint-disable-next-line import/no-cycle
import { BillingReCreateConfirmingModal } from '../Modal/BillingReCreateConfirmingModal';
import { HYPHEN } from '../../../Constants';
import { QuestionCircleTooltip } from '../../molecules/QuestionCircleTooltip';

export interface Props {
  billingMonth: string;
  data: BillingInvoiceListOutputResponse;
  setData: Dispatch<SetStateAction<BillingInvoiceListOutputResponse>>;
}

type MemoEditModalItem = {
  clientId: string | undefined;
  clientName: string | undefined;
  branchId: string | undefined;
  branchName: string | undefined;
  noteData: DeliveryNoteUpdate;
  setUpdateNoteData: Dispatch<SetStateAction<DeliveryNoteUpdate>>;
};

interface ReCreateModalItem {
  billingDestinationId: number;
  targetMonth: string;
  billingHeaderId?: number;
}

export const BillingListTable: React.VFC<Props> = ({ billingMonth, data, setData }) => {
  const [isEditMemoModal, setIsEditMemoModal] = useState<boolean>(false);
  const [isReCreateModal, setIsReCreateModal] = useState<boolean>(false);
  const [noteData, setUpdateNoteData] = useState<DeliveryNoteUpdate>({
    billingHeaderId: 0,
    deliveryNote: '',
    isTmp: true,
  });
  const [memoEditModalItem, setMemoEditModalItem] = useState<MemoEditModalItem>({
    clientId: '',
    clientName: '',
    branchId: '',
    branchName: '',
    noteData,
    setUpdateNoteData,
  });

  const [reCreateBillingItem, setReCreateBillingItem] = useState<ReCreateModalItem>({
    billingDestinationId: 0,
    targetMonth: '',
    billingHeaderId: undefined,
  });

  const billingApi = new BillingApi();

  // 配送メモ更新ボタン押下
  const handleClickEditMemo = (
    clientId: number | undefined,
    clientName: string | undefined,
    branchId: number | undefined,
    branchName: string | undefined,
    billingHeaderId: number | undefined,
    deliveryNote: string | undefined
  ) => {
    setUpdateNoteData({
      ...noteData,
      billingHeaderId: billingHeaderId === undefined ? 0 : billingHeaderId,
      deliveryNote: deliveryNote === undefined ? '' : deliveryNote,
    });
    setMemoEditModalItem({
      clientId: clientId?.toString(),
      clientName,
      branchId: branchId?.toString(),
      branchName,
      noteData,
      setUpdateNoteData,
    });
    setIsEditMemoModal(true);
  };

  // 再作成ボタン押下
  const handleClickReCreateBilling = (billingDestinationId: number, targetMonth: string, billingHeaderId?: number) => {
    setReCreateBillingItem({
      billingDestinationId,
      targetMonth,
      billingHeaderId,
    });
    setIsReCreateModal(true);
  };

  // 配送メモ更新
  const isFirstRender = useRef(false);
  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);
  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダーは更新しない
      isFirstRender.current = false;
    } else {
      if (noteData.billingHeaderId === undefined || isEditMemoModal) return; // 請求書ヘッダが無ければ更新しない

      billingApi
        .billingDeliveryNoteUpdate({
          billingHeaderId: noteData.billingHeaderId!,
          deliveryNote: noteData.deliveryNote,
          isTmp: noteData.isTmp,
        })
        .then((res) => {
          setData((prev) => ({
            ...prev,
            billingInvoiceListDetailOutput: prev.billingInvoiceListDetailOutput.map((v) => {
              return {
                ...v,
                billingInvoiceDestinationListOutputs: v.billingInvoiceDestinationListOutputs.map((vv) => {
                  if (vv.billingHeaderId === noteData.billingHeaderId) {
                    return {
                      ...vv,
                      deliveryNote: noteData.deliveryNote,
                    };
                  }
                  return vv;
                }),
              };
            }),
          }));
        });
    }
  }, [noteData]);

  // 空の項目は'ー'
  const hyphenOrContent = (content: string | number | undefined | null) => {
    if (content === undefined || content === null) {
      return HYPHEN;
    }
    return content;
  };

  const billingFlowStatusString = (status: number | undefined) => {
    return status !== undefined ? FLOW_STATUS_STRING.get(status) : HYPHEN;
  };

  // 要確認内容(備考)
  const billingheaderConfirmingString = (confirm: string[]) => {
    return confirm.map((v) => {
      return (
        <div style={{ color: 'red' }}>
          {v}
          <br />
        </div>
      );
    });
  };

  const nestedColumn = (
    index: number,
    item: BillingInvoiceListDetailOutputResponse,
    billing: BillingInvoiceDestinationListOutputResponse,
    salesInfo: BillingInvoiceDestinationSalesInfoOutputResponse
  ) => {
    return index === 0 ? (
      <tr key={index}>
        <td rowSpan={2}>
          {billing.branchNumber}:{billing.name}
        </td>
        <td>{hyphenOrContent(salesInfo.billingMonth)}</td>
        <td align="center">{hyphenOrContent(salesInfo.contractShopsCount)}</td>
        <td align="center">{hyphenOrContent(salesInfo.completedApplyCount)}</td>
        <td align="right">{convertMoneyText(salesInfo.amount)}</td>
        <td rowSpan={2}>
          {billingFlowStatusString(billing.billingFlowStatus)}
          <br />
          {billing.billingDeadLineStr}
        </td>
        <td rowSpan={2} align="right">
          <span className="d-block">{billing.visitedUncompleteApps}</span>
          <span className="d-block">{billing.nextMonthVisitApps}</span>
          <span className="d-block">{billing.pastVisitedApps}</span>
        </td>
        <td rowSpan={2}>
          {billing.deliveryNote}
          <br />
          <Button
            variant="secondary"
            onClick={() =>
              handleClickEditMemo(
                item.clientId,
                item.clientName,
                billing.branchNumber,
                billing.name,
                billing.billingHeaderId,
                billing.deliveryNote
              )
            }
          >
            配送メモ編集
          </Button>
        </td>
        <td rowSpan={2}>{billingheaderConfirmingString(billing.billingheaderConfirming)}</td>
        <td rowSpan={2}>
          {billing.billingHeaderId !== null && (
            <a
              href={`${Url.BILLING.BILLING_INVOICE_DETAIL}/${billing.billingHeaderId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              確認
            </a>
          )}
        </td>
        <td rowSpan={2}>
          {billing.billingFlowStatus !== undefined &&
            billing.billingFlowStatus <= BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED && (
              <Button
                variant="secondary"
                onClick={() =>
                  handleClickReCreateBilling(billing.billingDestinationId, billingMonth, billing.billingHeaderId)
                }
              >
                再作成
              </Button>
            )}
        </td>
      </tr>
    ) : (
      <tr key={index}>
        <td>({hyphenOrContent(salesInfo.billingMonth)})</td>
        <td align="center">({hyphenOrContent(salesInfo.contractShopsCount)})</td>
        <td align="center">({hyphenOrContent(salesInfo.completedApplyCount)})</td>
        <td align="right">{`(${hyphenOrContent(convertMoneyText(salesInfo.amount))})`}</td>
      </tr>
    );
  };

  const rowspan = (
    nested: boolean,
    item: BillingInvoiceListDetailOutputResponse,
    billing: BillingInvoiceDestinationListOutputResponse
  ) => {
    const rows = billing.billingBillingInvoiceDestinationSalesInfoOutput
      ? billing.billingBillingInvoiceDestinationSalesInfoOutput.length
      : 0;
    return nested ? rows : item.billingInvoiceDestinationListOutputs.length + rows;
  };

  return (
    <>
      {isEditMemoModal && (
        <BillingListMemoModal
          isModal={isEditMemoModal}
          setIsModal={setIsEditMemoModal}
          branchId={memoEditModalItem.branchId}
          branchName={memoEditModalItem.branchName}
          clientId={memoEditModalItem.clientId}
          clientName={memoEditModalItem.clientName}
          memo={noteData}
          setUpdateNoteData={setUpdateNoteData}
        />
      )}
      {isReCreateModal && (
        <BillingReCreateConfirmingModal
          isModal={isReCreateModal}
          setIsModal={setIsReCreateModal}
          billingDestinationId={reCreateBillingItem.billingDestinationId}
          targetMonth={reCreateBillingItem.targetMonth}
          billingHeaderId={reCreateBillingItem.billingHeaderId}
        />
      )}
      <Table>
        <thead>
          <tr className="text-nowrap">
            <th>クライアント</th>
            <th>代理店</th>
            <th>担当者名</th>
            <th>
              クライアント
              <br />
              合計
            </th>
            <th>請求先</th>
            <th>請求月</th>
            <th>店舗数</th>
            <th>完了数</th>
            <th>合計金額</th>
            <th>
              フロー
              <br />
              ステータス
            </th>
            <th>
              残応募
              <QuestionCircleTooltip tooltip="当月以前来店で当選中かつ未完了の応募数" />
              <br />
              翌月請
              <QuestionCircleTooltip tooltip="当月来店だが締め日の関係で翌月請求として出ている応募の数" />
              <br />
              前月繰
              <QuestionCircleTooltip tooltip="前月以前に来店した応募の数。tobの納品および枠の計算は来店日ベースで行われるため、この請求がある場合は契約枠を超えて請求が出ている可能性がある。" />
            </th>
            <th>配送メモ</th>
            <th>備考</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.billingInvoiceListDetailOutput.map((item) => {
            return item.billingInvoiceDestinationListOutputs.map(
              (billing: BillingInvoiceDestinationListOutputResponse, idx: number) => {
                return billing.billingBillingInvoiceDestinationSalesInfoOutput?.map(
                  (salesInfo: BillingInvoiceDestinationSalesInfoOutputResponse, index: number) => {
                    return idx === 0 && index === 0 ? (
                      <tr key={`${item.clientId}-${billing.billingDestinationId}-${index}`}>
                        <td rowSpan={2 * item.billingInvoiceDestinationListOutputs.length}>
                          {item.clientId}
                          <br />
                          {item.clientName}
                        </td>
                        <td rowSpan={2 * item.billingInvoiceDestinationListOutputs.length}>
                          {item.billingAgent?.map((agent) => {
                            return (
                              <>
                                {agent.id}:{agent.name}
                                <br />
                              </>
                            );
                          })}
                        </td>
                        <td rowSpan={2 * item.billingInvoiceDestinationListOutputs.length} align="right">
                          {item.billingStaffName}
                        </td>
                        <td rowSpan={2 * item.billingInvoiceDestinationListOutputs.length} align="right">
                          {convertMoneyText(item.amount)}
                        </td>
                        <td rowSpan={2}>
                          {billing.branchNumber}:{billing.name}
                        </td>
                        <td>{hyphenOrContent(salesInfo.billingMonth)}</td>
                        <td align="center">{hyphenOrContent(salesInfo.contractShopsCount)}</td>
                        <td align="center">{hyphenOrContent(salesInfo.completedApplyCount)}</td>
                        <td align="right">{convertMoneyText(salesInfo.amount)}</td>
                        <td rowSpan={2}>
                          {billingFlowStatusString(billing.billingFlowStatus)}
                          <br />
                          {billing.billingDeadLineStr}
                        </td>
                        <td rowSpan={2} align="right">
                          <span className="d-block">{billing.visitedUncompleteApps}</span>
                          <span className="d-block">{billing.nextMonthVisitApps}</span>
                          <span className="d-block">{billing.pastVisitedApps}</span>
                        </td>
                        <td rowSpan={rowspan(true, item, billing)}>
                          {billing.deliveryNote}
                          <br />
                          <Button
                            variant="secondary"
                            onClick={() =>
                              handleClickEditMemo(
                                item.clientId,
                                item.clientName,
                                billing.branchNumber,
                                billing.name,
                                billing.billingHeaderId,
                                billing.deliveryNote
                              )
                            }
                          >
                            配送メモ編集
                          </Button>
                        </td>
                        <td rowSpan={rowspan(true, item, billing)}>
                          {billingheaderConfirmingString(billing.billingheaderConfirming)}
                        </td>
                        <td rowSpan={rowspan(true, item, billing)}>
                          {billing.billingHeaderId !== null && (
                            <a
                              href={`${Url.BILLING.BILLING_INVOICE_DETAIL}/${billing.billingHeaderId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              確認
                            </a>
                          )}
                        </td>
                        <td rowSpan={rowspan(true, item, billing)}>
                          {billing.billingFlowStatus !== undefined &&
                            billing.billingFlowStatus <= BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED && (
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleClickReCreateBilling(
                                    billing.billingDestinationId,
                                    billingMonth,
                                    billing.billingHeaderId
                                  )
                                }
                              >
                                再作成
                              </Button>
                            )}
                        </td>
                      </tr>
                    ) : (
                      nestedColumn(index, item, billing, salesInfo)
                    );
                  }
                );
              }
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
