import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';

export interface BannerListUploadConfirmModalProps {
  message?: string;
  isUploading: boolean;
  onCancelUpload: () => void;
  onConfirmUpload: () => void;
}

export const BannerListUploadConfirmModal: React.FC<BannerListUploadConfirmModalProps> = ({
  message,
  isUploading,
  onCancelUpload,
  onConfirmUpload,
}) => {
  return (
    <Modal
      isModal={isUploading}
      centered
      onHide={onCancelUpload}
      title={message}
      body={
        <>
          <div className="d-flex justify-content-end m-2">
            <Button variant="outline-secondary" className="m-2" onClick={onCancelUpload}>
              キャンセル
            </Button>
            <Button variant="primary" className="m-2" onClick={onConfirmUpload}>
              OK
            </Button>
          </div>
        </>
      }
    />
  );
};
