/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BannerListOutputResponse } from '../domains';
// @ts-ignore
import { BannerListUploadFormResponse } from '../domains';
// @ts-ignore
import { BannerListUploadOutputResponse } from '../domains';
// @ts-ignore
import { BannerListUploadSettingsOutputResponse } from '../domains';
/**
 * BannerListApi - axios parameter creator
 * @export
 */
export const BannerListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * バナー広告設定一覧を取得する
         * @summary バナー広告設定一覧取得
         * @param {number} [bannerTypeId] 
         * @param {number} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerList: async (bannerTypeId?: number, category?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/banner-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bannerTypeId !== undefined) {
                localVarQueryParameter['bannerTypeId'] = bannerTypeId;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バナー広告設定一覧をアップロードする
         * @summary バナー広告設定一覧アップロード
         * @param {BannerListUploadFormResponse} bannerListUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUpload: async (bannerListUploadFormResponse: BannerListUploadFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerListUploadFormResponse' is not null or undefined
            assertParamExists('bannerListUpload', 'bannerListUploadFormResponse', bannerListUploadFormResponse)
            const localVarPath = `/api/banner-list-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerListUploadFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バナー広告設定一覧アップロード設定を取得する
         * @summary バナー広告設定一覧アップロード設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUploadSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/banner-list-upload-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerListApi - functional programming interface
 * @export
 */
export const BannerListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerListApiAxiosParamCreator(configuration)
    return {
        /**
         * バナー広告設定一覧を取得する
         * @summary バナー広告設定一覧取得
         * @param {number} [bannerTypeId] 
         * @param {number} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerList(bannerTypeId?: number, category?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerList(bannerTypeId, category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バナー広告設定一覧をアップロードする
         * @summary バナー広告設定一覧アップロード
         * @param {BannerListUploadFormResponse} bannerListUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerListUpload(bannerListUploadFormResponse: BannerListUploadFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerListUploadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerListUpload(bannerListUploadFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バナー広告設定一覧アップロード設定を取得する
         * @summary バナー広告設定一覧アップロード設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerListUploadSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerListUploadSettingsOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerListUploadSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerListApi - factory interface
 * @export
 */
export const BannerListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerListApiFp(configuration)
    return {
        /**
         * バナー広告設定一覧を取得する
         * @summary バナー広告設定一覧取得
         * @param {number} [bannerTypeId] 
         * @param {number} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerList(bannerTypeId?: number, category?: number, options?: any): AxiosPromise<BannerListOutputResponse> {
            return localVarFp.bannerList(bannerTypeId, category, options).then((request) => request(axios, basePath));
        },
        /**
         * バナー広告設定一覧をアップロードする
         * @summary バナー広告設定一覧アップロード
         * @param {BannerListUploadFormResponse} bannerListUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUpload(bannerListUploadFormResponse: BannerListUploadFormResponse, options?: any): AxiosPromise<BannerListUploadOutputResponse> {
            return localVarFp.bannerListUpload(bannerListUploadFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * バナー広告設定一覧アップロード設定を取得する
         * @summary バナー広告設定一覧アップロード設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUploadSettings(options?: any): AxiosPromise<BannerListUploadSettingsOutputResponse> {
            return localVarFp.bannerListUploadSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerListApi - object-oriented interface
 * @export
 * @class BannerListApi
 * @extends {BaseAPI}
 */
export class BannerListApi extends BaseAPI {
    /**
     * バナー広告設定一覧を取得する
     * @summary バナー広告設定一覧取得
     * @param {number} [bannerTypeId] 
     * @param {number} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerListApi
     */
    public bannerList(bannerTypeId?: number, category?: number, options?: AxiosRequestConfig) {
        return BannerListApiFp(this.configuration).bannerList(bannerTypeId, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * バナー広告設定一覧をアップロードする
     * @summary バナー広告設定一覧アップロード
     * @param {BannerListUploadFormResponse} bannerListUploadFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerListApi
     */
    public bannerListUpload(bannerListUploadFormResponse: BannerListUploadFormResponse, options?: AxiosRequestConfig) {
        return BannerListApiFp(this.configuration).bannerListUpload(bannerListUploadFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * バナー広告設定一覧アップロード設定を取得する
     * @summary バナー広告設定一覧アップロード設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerListApi
     */
    public bannerListUploadSettings(options?: AxiosRequestConfig) {
        return BannerListApiFp(this.configuration).bannerListUploadSettings(options).then((request) => request(this.axios, this.basePath));
    }
}
